.NavbarItems{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  width: 95%;
  height: 60px;
  border-radius: 35px;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  z-index: 1;
  background-color: #fff;
  position: fixed;
}


.navbar-logo{
  color: #115688;
  font-size: 0.5rem;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}

.nav-menu{
  display: grid;
  grid-template-columns: repeat(10,auto);
  grid-gap:10px;
  list-style: none;
  align-items: center;
}

.nav-menu a{
  text-decoration: none;
  color: #858583;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.7rem 1rem;
  white-space: nowrap;
  font-family: "Poppins", sans-serif;
}

.nav-links i{
  padding-right: 10px;
}

.nav-links-mobile{
  display: none;
}
.Navbar-logout{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:5px;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  background-color: red;
  color: #fff;
}

.Navbar-login{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:5px;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  background-color: #115688;
  color: #fff;
}

.menu-icons{
  display: none;
}

.Active {
  color: white !important;
  background-color: #115688; 
}

@media screen and (max-width: 1024px) {
  .nav-menu a {
    padding: 0.3rem 0.5rem; 
  }
  .nav-menu {
    grid-gap: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .NavbarItems {
    z-index: 99;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fff;
    width: 100%;
    height: auto;
    opacity: 1;
    align-items: stretch;
    position: absolute;
    top: 0;
    left: -100%;
    opacity: 0;
    padding: 80px 0 30px 0;
    margin: 0;
    transition: 0.3s ease-in-out;
    border-radius: 13px;
  }
  
  .nav-menu.active {
    left: 0;
    z-index: -1;
    opacity: 1;
    transition: 0.3s ease-in-out;
  }

  .nav-menu a {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    font-family: "Poppins", sans-serif;
    padding: 1rem 0;
  }

  .Navbar-login {
    display: none;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1rem;
    margin: auto;
    border-radius: 4px;
    width: 80%;
    background-color: #01959a;
    text-decoration: none;
    font-size: 1.2rem;
    font-family: "Poppins", sans-serif;
    color: #fff;
    font-weight: 700;
    letter-spacing: 2px;
  }
  .menu-icons {
    display: block;
    cursor: pointer;
  }
  .menu-icons i {
    font-size: 1.2rem;
    font-family: "Poppins", sans-serif;
    color: #222;
  }
  .Navbar-logout{
    width: 30%;
    margin: 0 auto;
  }
}
