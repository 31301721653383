.enquiryModal-container {
  display: flex;
  max-width: 800px;
  width: 100%;
  position: fixed;
  z-index: 111;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -55%);
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid #115277;
}

.enquiryModal-image {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #fff; */
  background-color: #072f5f;
}

.enquiryModal-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.enquiryModal-form {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* background-color: #072f5f; */
  background-color: #fff;
}

.enquiryModal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.enquiry-details-heading {
  font-weight: bold;
  font-size: 20px;
  color: #072f5f;
}

.enquiry-details-heading-icon {
  font-weight: bold;
  padding: 5px;
  color: #072f5f;
  background-color: white;
  border-radius: 5px;
}

.enquiry-details-input {
  margin-bottom: 15px;
  border-radius: 5px;
  background-color: white;
  height: 40px;
  border: 2px solid #333;
  padding-left: 10px;
}

.enquiry-details-textarea {
  height: 70px;
}

.enquiry-details-input-button {
  font-weight: bold;
  padding: 10px 20px;
  color: #fff;
  background-color: #072f5f;
  border-radius: 10px;
  margin: auto;
  width: 40%;
}

@media only screen and (max-width: 1024px) {


}

@media only screen and (max-width: 768px) {
  .enquiryModal-container {
    width: 90%;
    left: 5%;
    transform: translate(0, -55%);
  }

}

@media only screen and (max-width: 500px) {
  .enquiryModal-container {
    flex-direction: column;
  } 

  .enquiryModal-container {
    width: 90%;
    left: 5%;
    transform: translate(0, -55%);
  }

  .enquiryModal-image {
    display: none;
  }
  .enquiryModal-form {
    width: 100%;
  }
}
