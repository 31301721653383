.input-group {
    margin-top: 2rem;
}

/* .input-group input {
    max-width: 500px;
    height: 50px;
    padding: 20px;
    position: relative;
    font-family: "Poppins", sans-serif;
} */

.input-group button {
    position: relative;
    cursor: pointer;
    border-radius: 40px;
    background-color: #0EB614;
    color: #fff;
    left: -115px;
    padding: 10px 20px;
    margin: -8px;
    border: none;
    font-family: "Poppins", sans-serif;
}

.subscribe-button button.loading {
    cursor: not-allowed;
    pointer-events: none;
    background-color: #ccc;
}

.site-footer {
    background-color: #115688;
    padding: 30px 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}
.subscribe-section {
    margin-top: 20px;
}

.input-container {
    position: relative;
}
/* .input-container button {
    position: absolute;
    top: 0;
    right: 0;
    background-color:#0EB614;;
    color: white;
    border: none;
    padding: 8px 15px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;
} */


.footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
}

.footer-section {
    width: 20%;
}

.footer-section1 {
    width: 42%;
}

.footer-logo {
    font-size: 24px;
    font-family: "Poppins", sans-serif;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 20px;
}

.footer-heading {
    margin-bottom: 10px;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    color: #fff;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 20px;
}

.footer-content-para {
    color: #ccc;
    text-align: left;
    width: 90%;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
}

.footer-para {
    color: #ccc;
    text-align: left;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    text-decoration: none;
}

.footer-address {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-bottom: 10px;
}

.footer-address-heading {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    color: #fff;
}

.footer-para1 {
    display: flex;
    gap: 20px;
    color: #ccc;
    text-align: left;
    text-decoration: none;
}


.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section li {
    margin-bottom: 15px;
}

.footer-bottom {
    text-align: center;
    padding-top: 15px;
    color: #fff;
}



.footer-icons {
    margin-top: 25px;
    text-align: left;
}

.footer-icons a {
    display: inline-block;
    width: 35px;
    height: 30px;
    cursor: pointer;
    background-color: #33383b;
    border-radius: 2px;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;

    margin-right: 3px;
    margin-bottom: 5px;
}
.input-email{
    width: 500px; 
    height: 40px;
     border-Radius:40px;
     border:none;
     margin:10px;
     padding: 25px;
}
@media screen and (max-width: 600px) {
    .footer-content {
        flex-direction: column;
    }
  
    .input-group input {
        max-width: 350px;
        height: 50px;
        padding: 8px;
        position: relative;
        
    }
    .input-group button {
        cursor: pointer;
    border-radius: 40px;
    background-color: #0EB614;
    color: #FFF;
    font-weight: 600;
    margin: 10px;
    left: -135px;
    }
    .input-group {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    .footer-section,
    .footer-section1 {
        width: 100%; 
    }
    .input-group button {
        left: 0px;
        }  
}

@media screen and (max-width: 425px) {
    .input-group button {
    left: 0px;
    }   
}

@media screen and (max-width: 375px) {
    .input-group button {
    left: 0px;
    }   
      
    .input-group input {
        max-width: 300px;
    }
}