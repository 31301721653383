 /*banner*/
 .About-Img-container {
    display: flex;
    flex-wrap: wrap;
    padding-left: 60px;
    padding-top: 40px;
    padding-bottom: 20px;
    background: url(../../assets/college.png) right bottom/cover no-repeat;
    height: 460px;
  }

  .About-container-img-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .About-hero-text {
    position: absolute;
    top: 35%;
    left: 15%;
    transform: translate(-40%, -40%);
    width: 100%;
    font-family: "Poppins", sans-serif;
  }

  .About-hero-text h1 {
    font-size: 32px;
    line-height: 42px;
    font-weight: 800;
    background: #fff;
    background-clip: text;
    color: transparent;
  }


  .About-hero-text p {
    display: flex;
    justify-content: center;
    font-size: 1.3rem;
    color: #fff;
    padding: 0.5rem 0 2rem 0;

  }

  .About-Home-link {
    color: #fff;
  }

  .About-Home-link-span {
    color: #0073e6;
    font-weight: 600;
  }


  @media screen and (max-width: 768px) {
    .About-hero-text p {
      font-size: 1.1rem;
    }

    .About-hero-text h1 {
      font-size: 2rem;
      font-weight: 800;
      background: #fff;
      background-clip: text;
      color: transparent;
    }

    .About-hero-text {
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media (max-height: 555px) {
    .About-hero-text {
      top: 50%;
      left: 25%;
      transform: translate(-50%, -50%);
    }
  }

  @media (max-width: 500px) {
    .About-hero-text {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  
  @media screen and (max-width: 555px) {
    .About-Img-container {
      height: 300px;
    }
    .About-hero-text {
      top: 33%;
    }
  }

  /* part time */

  .parttime-job-heading {
    padding: 20px;
    font-family: "Poppins", sans-serif;
  }

  .parttime-job-applications {
    padding: 20px 20%;
    background-color: #F7FAFE;
  }

  .partime-jobs-name {
    display: flex;
    justify-content: center;
    margin: 10px;
    gap: 10px;
    font-family: "Poppins", sans-serif;
  }

  .parttime-jobs-label-name {
    text-align: left;
    padding: 5px 45px;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }

  .parttime-jobs-label {
    text-align: left;
    /* padding: 5px 45px; */
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }

  .partime-name-input {
    width: 423px;
    height: 40px;
    padding: 10px;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
  }

  .parttime-resume {
    border: 2px dotted #ccc;
    background-color: #F7FAFE;
    margin: 20px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .parttime-resume-icon {
    font-size: 50px;
    font-family: "Poppins", sans-serif;
  }

  @media (max-width: 920px) {
    .parttime-job-applications {
      padding: 20px 0%;
    }

    .partime-jobs-name {
      flex-direction: column;
      /* Revert to horizontal layout for larger screens */
    }

    .parttime-jobs-label {
      text-align: center;
    }

    .parttime-jobs-label-name {
      text-align: center;
      padding: 0px 0px;
      /* Adjust padding for larger screens */
    }

    .partime-name-input {
      width: 423px;
    }
  }

  @media (max-width: 500px) {
    .partime-name-input {
      width: 350px;
    }
  }

  @media (max-width: 400px) {
    .partime-name-input {
      width: 280px;
    }
  }


  .parttime-submit {
    padding: 10px 30px;
    background-color: #115277;
    color: #fff;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    border: none;
    border-radius: 20px;
    margin: 20px;
  }