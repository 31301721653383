/*banner*/
.About-Img-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;
  padding-top: 40px;
  padding-bottom: 20px;
  background-image: url(../../assets/courses.png) right bottom/cover no-repeat;
  height: 460px;
}

.About-container-img-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.About-hero-text {
  position: absolute;
  top: 35%;
  left: 15%;
  transform: translate(-40%, -40%);
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.About-hero-text h1 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 800;
  background: #fff;
  background-clip: text;
  color: transparent;
}


.About-hero-text p {
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  color: #fff;
  padding: 0.5rem 0 2rem 0;

}

.About-Home-link {
  color: #fff;
}

.About-Home-link-span {
  color: #0073e6;
  font-weight: 600;
}


@media screen and (max-width: 768px) {
  .About-hero-text p {
    font-size: 1.1rem;
  }

  .About-hero-text h1 {
    font-size: 2rem;
    font-weight: 800;
    background: #fff;
    background-clip: text;
    color: transparent;
  }

  .About-hero-text {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-height: 555px) {
  .About-hero-text {
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 500px) {
  .About-hero-text {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


@media screen and (max-width: 555px) {
  .About-Img-container {
    height: 300px;
  }
  .About-hero-text {
    top: 33%;
  }
}


/*Img and text on it */
/* styles.css */

.MapComponent {
  height: 400px;
  width: 100%;
}

/*cources container*/

.Contact-one-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 20px 10%;
  max-width: 1400px;
  margin: 5% auto;
}

.Contact-one-number {
  height: 250px;
  width: 30%;
  border: 1px solid #F8F9FF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #F8F9FF;
  border-radius: 10px;
}

.contact-heading {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding-top: 20px;
  color: #2a6a97;
}

.contact-value {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #858583;
}

Link {
  color: #858583;
}

.phone-icon {
  width: 60px;
  height: 55px;
}

.Contact-map {
  margin: 20px;
}


.google-map {
  width: 100%;
}

.google-map iframe {
  width: 100%;
  height: 400px;
  border: none;
}

/* form*/

.contact-form {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 5% 10%;
}
.contact{
  padding: 20px 10%;
}

.contact-form h2 {
  text-align: center;
  padding: 20px;
}

.contact-form-input {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.contact-input-group {
  position: relative;
  flex: 48%;
  margin-bottom: 20px;
}

.input-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 96%;
}

.contact-input-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 92%;
}

.contact-input {
  width: 100%;
  height: 45px;
  padding-left: 30px;
  padding: 10px;
  background-color: #FBFCFC;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-input-subject {
  width: 100%;
  height: 45px;
  padding-left: 30px;
  padding: 10px;
  background-color: #FBFCFC;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-input-message {
  width: 100%;
  padding-left: 30px;
  padding: 10px;
  background-color: #FBFCFC;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-input-group-one {
  position: relative;
  flex: 100%;
  margin-bottom: 20px;
}

textarea {
  resize: vertical;
}

.contact-button {
  background-color: #115678;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

@media screen and (max-width:1024px) {
  .input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 95%;
  }

  .contact-input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 92%;
  }

  .Contact-one-container {
    padding: 20px 0%;
  }
  .contact {
    padding: 20px 10%;
    margin: 20px;
}
}

@media screen and (max-width:768px) {
  .input-icon {
    left: 92%;
  }

  .contact-input-icon {
    left: 88%;
  }

  .Contact-one-container {
    padding: 20px 5%;
    flex-direction: column;
  }

  .Contact-one-number {
    height: 250px;
    width: 45%;
  }
  .contact-form {
    margin: 5% 20px;
  }

}

@media screen and (max-width:699px) {
  .Contact-one-number {
    width: 100%;
  }

  .input-icon {
    left: 88%;
  }
  .contact-form-input {
  flex-direction: column;
  gap: 0px;
  }
  .contact {
    padding: 20px 5%;
    margin: 20px;
}
}


@media screen and (max-width:375px) {
  .Contact-container {
    padding: 20px 2%;
  }
}