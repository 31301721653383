/*banner*/
.About-Img-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;
  padding-top: 40px;
  padding-bottom: 20px;
  background-image: url(../../assets/college.png) right bottom/cover no-repeat;
  height: 460px;
}

.About-container-img-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.About-hero-text {
  position: absolute;
  top: 35%;
  left: 15%;
  transform: translate(-40%, -40%);
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.About-hero-text h1 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 800;
  background: #fff;
  background-clip: text;
  color: transparent;
}


.About-hero-text p {
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  color: #fff;
  padding: 0.5rem 0 2rem 0;

}

.About-Home-link {
  color: #fff;
}

.About-Home-link-span {
  color: #0073e6;
  font-weight: 600;
}


@media screen and (max-width: 768px) {
  .About-hero-text p {
    font-size: 1.1rem;
  }

  .About-hero-text h1 {
    font-size: 2rem;
    font-weight: 800;
    background: #fff;
    background-clip: text;
    color: transparent;
  }

  .About-hero-text {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-height: 555px) {
  .About-hero-text {
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 500px) {
  .About-hero-text {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


@media screen and (max-width: 555px) {
  .About-Img-container {
    height: 300px;
  }
  .About-hero-text {
    top: 33%;
  }
}


  /* Testimonial*/
.testimonial-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Testimonial items */
  .testimonial-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  /* Testimonial item */
  .testimonial-item {
    width: calc(33.33% - 20px); 
    margin-bottom: 20px;
    background-color: #f9f9f9;
    padding: 30px;
    box-sizing: border-box;
  }
  
  /* Testimonial image */
  .testimonial-img-body {
    margin-bottom: 10px;
  }
  
  .testimonial-img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  
  /* Testimonial name container */
  .testimonial-name-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
    gap: 30px;
  }
  
  .testimonial-review-name {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .testimonial-review-icon {
    color: #115688;
}
  /* Testimonial rating */
  .testimonial-review-rating {
    display: flex;
    align-items: center;
    color: #115688;
  }
  
  .testimonial-review-icon {
    margin-right: 5px;
  }
  
  /* Testimonial content */
  .testimonial-review-para {
    color: #555;
    text-align: justify;
  }

  .testimonial-img{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin: 20px auto;
    object-fit: cover;
    overflow: hidden;
}
  
@media screen and (max-width: 768px) {
.testimonial-items {
  flex-direction: column;
}
.testimonial-item {
  width: 100%;
}
}