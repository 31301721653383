/*banner*/
.About-Img-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;
  padding-top: 40px;
  padding-bottom: 20px;
  background-image: url(../../assets/freeresources.png) right bottom/cover no-repeat;
  height: 460px;
}

.About-container-img-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.About-hero-text {
  position: absolute;
  top: 35%;
  left: 15%;
  transform: translate(-40%, -40%);
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.About-hero-text h1 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 800;
  background: #fff;
  background-clip: text;
  color: transparent;
}


.About-hero-text p {
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  color: #fff;
  padding: 0.5rem 0 2rem 0;

}

.About-Home-link {
  color: #fff;
}

.About-Home-link-span {
  color: #0073e6;
  font-weight: 600;
}


@media screen and (max-width: 768px) {
  .About-hero-text p {
    font-size: 1.1rem;
  }

  .About-hero-text h1 {
    font-size: 2rem;
    font-weight: 800;
    background: #fff;
    background-clip: text;
    color: transparent;
  }

  .About-hero-text {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-height: 555px) {
  .About-hero-text {
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 500px) {
  .About-hero-text {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


@media screen and (max-width: 555px) {
  .About-Img-container {
    height: 300px;
  }
  .About-hero-text {
    top: 33%;
  }
}


/* FreeResources.css */
.Free-Resources {
  font-family: Arial, sans-serif;
  padding: 20px 5%;
}

.Free-Resources-heading {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  flex-wrap: wrap;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}

.free-Resource-header {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  border-radius: 40px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #115688;
  font-family: "Poppins", sans-serif;
}

.free-resources-icon {
  font-size: 18px;
  margin-right: 5px;
  font-family: "Poppins", sans-serif;
}

.free-Resource-header.active {
  background-color: #115688;
  color: #fff;
}

.free-Resource-content {
  padding: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.category_buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.button button {
  background-color: #ffffff;
  color: #0056b3;
  outline: none;
  border: 2px solid #0056b3;
  margin: 0px;
  padding:10px 30px;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 40px;
  cursor: pointer;
}
.button .active {
  background-color: #0056b3;
  color: white;
}

.button button:hover {
  background-color: #064a93;
  color: #ffff;
}

.ebook-container {
  display: flex;
  border-bottom: 2px solid #ccc;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.ebook-container {
  margin: 20px 5%;
  padding: 20px;
}

.ebook-image {
  border: 1px solid #ccc;
  padding: 10px 10px;
  border-radius: 5px;
}

.ebook-download {
  width: 10%;
}

.ebook-description {
  width: 70%;
  text-align: left;
  font-family: "Poppins", sans-serif;
}

.ebook-description h3 {
  color: #115688;
  padding-bottom: 10px;
  font-family: "Poppins", sans-serif;
}

.ebook-image-icon {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  color: #a6a900;
}

.ebook-download-icon {
  color: #000;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

@media screen and (max-width: 726px) {
  .Free-Resources-heading {
    flex-direction: column;
  }
  .ebook-description {
    text-align: justify;
  }
  .ebook-container {
    margin: 0px;
    padding: 10px 0px;
  }
  .ebook-container {
    gap: 10px;
  }
  .ebook-description h3 {
    text-align: center;
  }
}

@media screen and (max-width: 425px) {
  .ebook-description {
    text-align: justify;
  }
  .Free-Resources-heading {
    flex-direction: column;
  }
  .ebook-container {
    margin: 0px;
  }
  .ebook-container {
    gap: 10px;
  }
  .ebook-image-icon {
    font-size: 18px;
  }

  .ebook-download-icon {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
  }
  .ebook-image {
    padding: 5px 5px;
  }
  .ebook-description h3 {
    text-align: center;
  }
}

/*webiner*/
.webiner-tab-content {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 10%;
}

.webiner-content-card {
  width: 40%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 30px;
  background: white;
}

.webiner-card {
  text-align: left;
}

.webiner-card h2 {
  font-size: 20px;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
}

.webiner-card p {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.webiner-content-card img {
  width: 100%;
  height: 170px;
  border-radius: 5px;
  margin-top: 10px;
}

.webiner-date-time {
  text-align: left;
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #858583;
}

.webiner-date-time p {
  margin-top: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
}

.webiner-button {
  background-color: #115688;
  color: #fff;
  padding: 15px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.webiner-button:hover {
  background-color: #0056b3;
}

@media screen and (max-width: 1024px) {
  .webiner-content-card {
    width: 48%;
  }
}

@media screen and (max-width: 768px) {
  .webiner-content-card {
    width: 80%;
  }
  .webiner-tab-content {
    margin: 0;
  }
}

@media screen and (max-width: 425px) {
  .webiner-content-card {
    width: 90%;
  }
  .webiner-tab-content {
    margin: 0;
  }
}

@media screen and (max-width: 375px) {
  .webiner-content-card {
    width: 100%;
  }
  .webiner-tab-content {
    margin: 0;
  }
  .free-Resource-content {
    padding: 0px;
  }
}

/* Videos */

.video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.video-wrapper {
  width: calc(33.33% - 20px);
  margin: 10px;
  text-align: center;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  font-family: "Poppins", sans-serif;
}

.video-wrapper video {
  width: 100%;
}

.video-wrapper h3 {
  margin-top: 10px;
  margin-bottom: 5px;
  font-family: "Poppins", sans-serif;
}

.video-wrapper p {
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 1024px) {
  .video-wrapper {
    width: calc(50% - 20px);
  }
}

@media screen and (max-width: 768px) {
  .video-wrapper {
    width: calc(100% - 20px);
  }
}

/*pagination*/
/*pagination*/
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
  margin-top: 20px;
}

.pagination li {
  margin: 0 5px;
  cursor: pointer;
}

.pagination li a {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  text-decoration: none;
}

.pagination li.active a {
  background-color: #115277;
  color: #fff;
  border-color: #115277;
}
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
