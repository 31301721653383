
/*banner*/
.About-Img-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;
  padding-top: 40px;
  padding-bottom: 20px;
  background:url(../../assets/college.png) right bottom/cover no-repeat;
  height: 460px;
}

.About-container-img-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.About-hero-text {
  position: absolute;
  top: 35%;
  left: 15%;
  transform: translate(-40%, -40%);
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.About-hero-text h1 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 800;
  background: #fff;
  background-clip: text;
  color: transparent;
}


.About-hero-text p {
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  color: #fff;
  padding: 0.5rem 0 2rem 0;

}

.About-Home-link {
  color: #fff;
}

.About-Home-link-span {
  color: #0073e6;
  font-weight: 600;
}


@media screen and (max-width: 768px) {
  .About-hero-text p {
    font-size: 1.1rem;
  }

  .About-hero-text h1 {
    font-size: 2rem;
    font-weight: 800;
    background: #fff;
    background-clip: text;
    color: transparent;
  }

  .About-hero-text {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-height: 555px) {
  .About-hero-text {
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 500px) {
  .About-hero-text {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


@media screen and (max-width: 555px) {
  .About-Img-container {
    height: 300px;
  }
  .About-hero-text {
    top: 33%;
  }
}


.navbar-container {
  background-color: #064a93;
  height: 100px;
}


/*cources container*/
.courses-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  width: 100%;
  max-width: 1000px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
}

.courses {
  background-color: #f7fafe;
}

.courses-item {
  text-align: center;
  border: 2px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.courses-img {
  width: 70px;
  height: 70px;
}

.courses-title {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #064a93;
  padding: 5px;
  text-decoration: none;
  -webkit-box-orient: vertical;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}


.courses-paragraph {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #858583;
  padding: 5px;
  text-decoration: none;
  -webkit-box-orient: vertical;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width: 1496px) {
  .courses-container {
    grid-template-columns: repeat(3, 1fr);
  }
}


@media screen and (max-width: 768px) {
  .courses-container {
    width: 90%;
    grid-template-columns: repeat(2, 1fr);
  }

  .courses-item {
    height: fit-content;
  }
}

@media screen and (max-width: 555px) {
  .courses-container {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
  .courses-item {
    width: 80%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 350px) {
  .courses-container {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
  .courses-item {
    width: 100%;
    margin: 0 auto;
  }
}

/*coaching page*/

.menu {
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.menu-icon-name {
  padding: 5px;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.menu-heading {
  color: #064a93;
  font-size: 32px;
  line-height: 48px;
  padding: 10px;
}

.menu-list {
  list-style: none;
  display: flex;
  padding: 10px;
  justify-content: center;

  flex-wrap: wrap;
  margin: 0;
}

.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #064a93;
  text-decoration: none;
  margin: 0 10px;
}

.menu-icon {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: #fff;
}

.menu-icon-box {
  background-color: #064a93;
  padding: 7px 12px;
  border-radius: 25px;
}

.Coachingpage{
  max-width: 1250px;
  margin: 20px auto;
}
.coachingpage-two-img-container{
  width: 50%;
}
.coachingpage-two-img {
  max-width: 100%;
  height: auto;
}

.coachingpage-two-text {
  width: 50%;
  text-align: justify;
  padding: 20px;
}

.coachingpage-two-text h3 {
  color: #064a93;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
}

.coachingpage-two-text p {
  color: #000;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 30px;
}

.coachingpage-three {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.coaching2-img {
  max-width: 92%;
  height: auto;
}

.coachingpage-three-img {
  width: 48%;
}

.coachingpage-three-text {
  width: 48%;
  background-color: #f7fafe;
  border-radius: 10px;
}

.coachingpage-three-list {
  list-style-type: none;
  text-align: left;
  line-height: 7.5vh;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
}

.coachingpage-three-list li {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #858583;
}

.coachingpage-three-icon {
  margin-right: 10px;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  color: #064a93;
}

@media screen and (max-width: 768px) {
  .menu,
  .menu-list {
    flex-direction: column;
  }

  .coachingpage-three-img,
  .coachingpage-three-text {
    width: 100%;
  }

  .coachingpage-three {
    flex-direction: column;
  }

  .Coachingpage {
    padding: 0px 10px;
  }

  .coachingpage-three-list {
    line-height: 5vh;
  }
}

@media screen and (max-width: 1400px) {
  .coachingpage-three-list {
    line-height: 6vh;
  }
}

@media screen and (max-width: 1024px) {
  .coachingpage-three-img,
  .coachingpage-three-text {
    width: 100%;
  }

  .coachingpage-three {
    flex-direction: column;
  }

  .menu {
    flex-direction: column;
  }
}

@media screen and (max-width: 375px) {
  .coachingpage-three-list {
    line-height: 4vh;
  }

  .coachingpage-three-icon {
    font-size: 24px;
    color: #064a93;
  }
}

@media screen and (max-width: 425px) {
  .coachingpage-three-list {
    line-height: 4vh;
  }

  .coachingpage-three-icon {
    font-size: 24px;
    color: #064a93;
  }
}
/*three*/
.Coachingpage-three-container{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:20px;
  padding: 20px 5%;
}

/*four*/
.coachingpage-four {
  padding: 10px 20px;
}

.coachingpage-four-container {
  padding: 0px 10px;
}

.coachingpage-four-img {
  max-width: 100%;
  height: auto;
  padding: 20px;
}

.coachingpage-four-container h3 {
  color: #064a93;
  font-size: 32px;
  line-height: 48px;
  font-family: "Poppins", sans-serif;
  padding: 20px;
}

.coachingpage-four-text {
  display: flex;
  gap: 10px;
  line-height: 30px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.coachingpage-four-text-one,
.coachingpage-four-text-two {
  width: 48%;
  text-align: left;
  line-height: 30px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  padding: 20px;
}

.coachingpage-four-text-three {
  width: 100%;
  padding: 10px 40px;
  text-align: left;
  line-height: 28px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 765px) {
  .coachingpage-four-text {
    flex-direction: column;
  }

  .coachingpage-four-text-one,
  .coachingpage-four-text-two,
  .coachingpage-four-text-three {
    width: 100%;
    text-align: justify;
  }
}

@media screen and (max-width: 425px) {
  .coachingpage-four-text {
    flex-direction: column;
  }

  .coachingpage-four-text-one,
  .coachingpage-four-text-two,
  .coachingpage-four-text-three {
    width: 100%;
    text-align: justify;
  }
}

/*3cards*/

.Pricing-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.pricing-cards-container {
  display: flex;
  gap: 10px;
  padding: 20px;
  justify-content: left;
  align-items: center;
}

.pass-card {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 20px;
  width: 350px;
  text-align: center;
}

.pass-card .pro {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pass-card-header .package-type {
  color: #0073e6;
  font-weight: bold;
  /* padding: 20px; */
}

.package-type {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
}

.card-header h2 {
  margin: 10px 0;
}

.IELTSicon {
  width: 60px;
  height: auto;
}

.package-paragraph {
  color: #858583;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 500;
  text-align: left;
}

.price {
  font-size: 35px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  text-align: left;
  padding-bottom: 10px;
}

.period {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #666;
}

.pass-card-body h3 {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 10px;
  text-align: left;
}

.pass-card-body ul {
  list-style: none;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
}

.pass-card-body li {
  margin-bottom: 10px;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}

.checkmarkIcon {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #064a93;
  margin-right: 10px;
}

.package-paragraph-points {
  list-style: none;
  padding: 0;
}

.package-paragraph-points li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.checkmarkIcon {
  margin-right: 5px;
}

.card-footer {
  padding: 0px;
}

.card-footer button {
  background-color: #0073e6;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.card-footer button:hover {
  background-color: #005bb5;
}

@media screen and (max-width: 726px) {
  .pricing-cards-container {
    flex-direction: column;
  }

  .pass-card {
    width: 300px;
  }

  .pass-card-body li {
    font-size: 11px;
  }
}

/*form */

.free-immigration-assessment {
  max-width: 900px;
  margin: 20px auto;
  background-color: #f9f9f9;
  padding:5% 40px;
  border-radius: 8px;
}

.free-immigration {
  display: flex;
  gap: 10px;
}

.free-immigration-form {
  width: 50%;
}

.free-immigration-assessment h2 {
  margin-bottom: 20px;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  color: #333;
  text-align: left;
  padding: 10px;
}

.form-row {
  margin-bottom: 15px;
  position: relative;
}
.form-row .textpage-durations{
  text-align: left;
  color: red;
}
.from_button {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
}
.from_button button {
  background-color: #064a93;
  color: #ffff;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin: 0px;
  padding: 0.5rem 1rem;
  border: 1px solid #064a93;
  outline: none;
  border-radius: 5px;
}



.free-immigration-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

textarea {
  height: 100px;
}

.card-footer-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.card-footer-button:hover {
  background-color: #064a93;
}

/* Media query for screen width 425px and below */
@media screen and (max-width: 425px) {
  .free-immigration {
    flex-direction: column;
  }

  .free-immigration-form {
    width: 100%;
  }
}

@media screen and (max-width: 760px) {
  .free-immigration {
    flex-direction: column;
  }

  .free-immigration-form {
    width: 100%;
  }

  .free-immigration-assessment {
    width: 100%;
    margin: 0px;
  }
}

/*testpage*/

.textpage-container {
 padding: 20px 10%;
}

.textpage-heading {
  text-align: center;
  color: #333;
  padding: 10px;
  background-color: #f7fafe;
}

.textpage-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 20px 0;
}

.textpage-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.textpage-card img {
  width: 100%;
  height: 220px;
  object-fit: cover;
}

.textpage-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 15px;
}

.textpage-card-content h2 {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #333;
  text-align: left;
}
.textpage-price-card{
  display: flex;
  justify-content:space-between;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  margin-bottom:5px;
}
.textpage-card-content p {
  font-size:16px;
  font-family: "Poppins", sans-serif;
  color: #666;
  text-align: left;
}
.span_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.span_container p {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #ffaa1d;
}
.span_container button {
  background-color: #064a93;
  color: #ffff;
  border: 2px solid #064a93;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  margin-top:5px;
  margin-bottom:5px;
  font-weight: 500;
  padding:5px 50px;
  border-radius: 36px;
}

.textpage-durations {
  font-size: 1.1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #064a93;
  background-color: #f7fafe;
  padding: 8px 30px;
}

.span_container {
  background-color: tra;
}

.textpage-container-text {
  text-align: center;
  padding-bottom: 20px;
}

.textpage-container-text h3,
.textpage-container-text h4 {
  padding: 10px 5px;
}

.textpage-container-menu-heading{
  color: #064a93;
    font-size: 32px;
    line-height: 48px;
    padding: 10px;
}

.textpage-container-text h3 {
  font-weight: 600;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

.textpage-container-text h4 {
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.textpage-container-text p {
  padding: 5px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

.textpage-container-paragraph {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f7fafe;
}

.textpage-container-paragraph-button {
  padding: 10px 20px;
  background-color: #064a93;
  color: #fff;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  margin: 20px;
}

/*coursespage*/
/* FreeResources.css */
.Free-Resources {
  font-family: "Poppins", sans-serif;
  margin: auto;
}


.free-Resource-header {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  border-radius: 40px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #064a93;
}

.free-Resource-header.active {
  background-color: #064a93;
  color: #fff;
}

.free-resources-icon {
  font-size: 18px;
  margin-right: 5px;
  font-family: "Poppins", sans-serif;
}



/*listening*/
.audio-quiz-container {
  position: relative;
}

.audio-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.audio-time {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.audio-icons-top {
  padding-left: 30px;
}

.audio-icon {
  cursor: pointer;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
}

.audio-buttons-top {
  padding-left: 30px;
  display: flex;
}

.audio-button-preview {
  padding: 10px 30px;
  margin-left: 20px;
  border-radius: 5px;
  display: flex;
  gap: 10px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  background-color: #fff;
  color: #000;
  justify-content: center;
  align-items: center;
}

.audio-button-submit {
  padding: 10px 30px;
  margin-left: 20px;
  border-radius: 5px;
  display: flex;
  gap: 10px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  background-color: green;
  color: #fff;
  justify-content: center;
  align-items: center;
  border: none;
}

.audio-player-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  gap: 50px;
}

.rhap_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row !important;
  line-height: 1;
  font-family: inherit;
  width: 50% !important;
  padding: 10px 15px;
  background-color: #ccc !important;
  box-shadow: none !important;
}

.rhap_progress-indicator {
  background: #064a93 !important;
}

.rhap_main-controls-button {
  color: #064a93 !important;
}

#sectionSelect {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  padding: 10px 30px;
  border-radius: 5px;
  color: #064a93;
  background-color: #fff;
  border: none;
}

.audio-questions {
  padding: 20px;
  width: 100%;
}

.audio-questions h2 {
  text-align: left;
  color: #064a93;
}

.question-Blanks {
  display: flex;
  gap: 20px;
  padding: 20px;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

.question-Blanks-questions {
  display: flex;
  gap: 10px;
}

.question-choose {
  gap: 20px;
  padding: 20px;
}

.audio-questions-input {
  border-radius: 10px;
  padding: 5px;
  border: 2px solid #000;
}

.audio-choose-radio {
  display: flex;
  margin-bottom: 5px;
  gap: 10px;
  font-size: 18px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.notepad {
  position: fixed;
  top: 70px;
  right: -300px;
  width: 400px;
  height: 700px;
  background: #f9f9f9;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease;
  z-index: 1000;
}

.notepad.visible {
  right: 0;
}

textarea {
  width: 100%;
  height: 100%;
  border: none;
  padding: 20px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
}

.audio-questions {
  margin-top: 20px;
}

.audio-question-choose {
  margin-bottom: 20px;
}

.audio-question-info {
  display: flex;
  align-items: flex-start;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding-bottom: 5px;
}

.audio-question-number {
  margin-right: 10px;
}

.audio-options-container {
  display: flex;
  flex-direction: column;
}

/*writing*/
.writing-container {
  padding: 0px;
}


.tab-buttons {
  display: flex;
  margin: 20px;
}

.tab-button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: #f0f0f0;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  transition: background-color 0.3s ease;
}

.tab-button.active,
.tab-button:hover {
  background-color: #d0d0d0;
}

.task-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
}

.task-image {
  max-width: 100%;
  margin: 20px 0;
}

.task-textarea {
  width: 100%;
  height: 200px;
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
}

.word-count {
  margin-top: 10px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #666;
}

.highlight {
  font-weight: 500;
  font-size: 18px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 764px) {
  .Free-Resources {
 padding: 0px;
  }
  .audio-toolbar {
    flex-direction: column;
  }

  .audio-buttons-top {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0px;
  }

  .audio-icons-top {
    padding-left: 0px;
  }

  .audio-player-container {
    flex-direction: column;
    gap: 10px;
    padding: 20px;
  }

  .rhap_container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row !important;
    line-height: 1;
    font-family: inherit;
    width: 100% !important;
    padding: 10px 15px;
    background-color: #ccc !important;
    box-shadow: none !important;
  }

  .question-Blanks {
    flex-direction: column;
    padding: 10px;
    text-align: left;
  }

  .audio-questions h2 {
    text-align: center;
    color: #064a93;
  }

  .audio-question-text {
    text-align: left;
  }

  .question-choose {
    padding: 10px;
    gap: 10px;
  }
}

.Free-Resources-heading {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 20px;
  flex-wrap: wrap;
  cursor: pointer;
}

@media screen and (max-width: 780px) {
  .Free-Resources-heading {
    flex-direction: column;
  }
  .Coachingpage-three-container{
    flex-direction: column;
  }
  .coachingpage-two-img-container,.coachingpage-two-text{
    width: 100%;
  }
}