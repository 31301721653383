.question-option{
    list-style-type: none;
    display: flex;
    gap: 10px;
}

.verifyquestions{
    display: flex;
    height: 100vh;
}
.verifyquestions-container {
    width: 30%;
    background-color: #fff;
    box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.1);
    text-align: left; 
    display: flex;
    flex-direction: column;
    /* justify-content: center;  */
    align-items: center; 
    padding-top: 80px;
    padding-bottom: 80px;

}
.emailVerified{
    color: #858583;
}
.emailNotVerified{
    color: #115677;
}
.question-text{
    color: #115677;
    font-size: 20px;
    font-family: 500;
    padding: 10px;
}
.options-list{
    padding: 10px;  
    font-size: 16px;
    font-family: 400;
    color: #6F6F70;
}
.verifyquestions-container h1{
    color: #115677;
    padding-top: 20px;
    padding-bottom: 20px;
}

.verifyquestions-container-two{
    width: 70%;
    background-color: #f8f6ff;
    align-items: center; 
    text-align: left; 
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.verifyquestions-container-ul {
    list-style: none;
    padding: 0;
    text-align: center; 
    padding: 20px;
  
}

.verifyquestions-container-ul li {
    display: flex;
    align-items: center; 
    margin-bottom: 10px;
}

.verifyquestions-container-ul input[type="checkbox"],
.verifyquestions-container-ul input[type="radio"] {
    margin-right: 10px;
}

.verifyquestions-container label {
    margin: 0;
}

@media (max-width: 1024px) {
   
    .verify-button {
  
      width: 95%;
      
    }
  }
  @media (max-width: 699px) {
    .verifyquestions{
       flex-direction: column;
       height: 100vh;
    }
  .verifyquestions-container {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;

}

.verifyquestions-container-two{
    flex-direction: column;
    width: 100%;
    padding: 20px;
    
}

}

.verify-next-button{
    padding: 10px 30px;
    font-size: 16px;
    background-color: #115677;
    color: #fff;
    border: none;
    border-radius: 30px;

}
.question-option-input-text-field{
    padding: 10px;
    margin: 10px 0px;
    border-radius: 8px;
    border: none;
    color: #000;
}
.question-option-input-text-field::placeholder {
    color: #333;
    font-weight: 500;
  }
  .error-message{
    color: red;
  }