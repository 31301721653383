/*banner*/
.About-Img-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;
  padding-top: 40px;
  padding-bottom: 20px;
  background:url(../../assets/freeresources.png) right bottom/cover no-repeat;
  height: 460px;
}

.About-container-img-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.About-hero-text {
  position: absolute;
  top: 35%;
  left: 15%;
  transform: translate(-40%, -40%);
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.About-hero-text h1 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 800;
  background: #fff;
  background-clip: text;
  color: transparent;
}


.About-hero-text p {
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  color: #fff;
  padding: 0.5rem 0 2rem 0;

}

.About-Home-link {
  color: #fff;
}

.About-Home-link-span {
  color: #0073e6;
  font-weight: 600;
}


@media screen and (max-width: 768px) {
  .About-hero-text p {
    font-size: 1.1rem;
  }

  .About-hero-text h1 {
    font-size: 2rem;
    font-weight: 800;
    background: #fff;
    background-clip: text;
    color: transparent;
  }

  .About-hero-text {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-height: 555px) {
  .About-hero-text {
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 500px) {
  .About-hero-text {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


@media screen and (max-width: 555px) {
  .About-Img-container {
    height: 300px;
  }
  .About-hero-text {
    top: 33%;
  }
}

/*blogs*/

.Blog-card-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.free-immigration-assessment-header {
  padding-top: 10px;
  padding-bottom: 10px;
}

.free-immigration-assessment-header p {
  text-align: left;
}

.Blog-card {
  width: 30%;
  margin-bottom: 20px;
}

.Blog-card-image img {
  width: 100%;
  height: auto;
}

.Blog-card-content {
  padding: 10px;
  background: #fff;
}

.Blog-card-details {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.Blog-card-title-des {
  margin-bottom: 10px;
}

.Blog-card-read-more {
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
}

.Blog-card-icon {
  margin-right: 5px;
}


.Blog-content {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
}


.readmore-hero-text {
  position: absolute;
  top: 30%;
  left: 15%;
  transform: translate(-40%, -40%);
  width: 100%;
}

.readmore-hero-text h1 {
  font-size: 3rem;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  background: #fff;
  background-clip: text;
  color: transparent;
}

.readmore-hero-text p {
  font-size: 1.3rem;
  font-family: "Poppins", sans-serif;
  color: #fff;
  padding: 0.5rem 0 2rem 0;
}

@media screen and (max-width: 555px) {
  .readmore-hero-text p {
    font-size: 1.1rem;
    color: #fff;
    padding: 0 0 2rem 0;
  }

  .readmore-hero-text {
    position: absolute;
    top: 30% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .Blogs-Img-container {
    height: 300px;
  }
}

@media screen and (max-width: 768px) {
  .readmore-hero-text p {
    font-size: 1.3rem;
    color: #fff;
    padding: 0 0 2rem 0;
  }

  .readmore-hero-text {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .readmore-hero-text h1 {
    font-size: 2.5rem;
    font-weight: 800;
    background: #fff;
    background-clip: text;
    color: transparent;
  }
}

/*Blogs*/

.Blog-card-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: auto 40px;
  padding:40px 5%;
  border-top: 2px solid #ddd;
}


.Blog-card {
  width: calc(30% - 20px);
  margin-bottom: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  text-overflow: ellipsis;
  height: 510px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}



.Blog-card-image img {
  width: 100%;
  height: 250px;
  display: block;
  border-top-left-radius:10px;
  border-top-right-radius: 10px;
}

.Blog-card-content {
  padding: 16px;
}

.Blog-card-title-des {
  height: 120px;
}

.Blog-card-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  padding: 10px;
  width: 70%;
  position: relative;
  top: -35px;
  background-color: #ffff;
  left: -16px;
  height: 50px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.Blog-card-icon {
  color: #115688;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

.Blog-card-author,
.Blog-card-date {
  font-size: 0.8rem;
  font-family: "Poppins", sans-serif;
  display: flex;
  color: #858583;
}

.Blog-card-title {
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  margin-bottom: 3px;
  color: #115688;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.Blog-card-description {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  color: #858583;
  margin-bottom: 16px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.Blog-card-read-more {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: #115688;
  text-decoration: none;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
}

.Blog-card-read-more:hover {
  text-decoration: underline;
}

.Blog-card-read-more svg {
  margin-left: 5px;
  margin-top: 4px;
}

/* Responsive styles */

@media screen and (max-width: 1024px) {
  .Blog-card {
    width: calc(40% - 20px);
  }
  .Blog-card-row {
    margin: auto 0px;
  }

  .Blog-card-details {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .Blog-card {
    width: calc(50% - 20px);
  }
  .Blog-card-row {
    margin: auto 0px;
    padding:20px;
    border-top: 2px solid #ddd;
  }
  
}

@media screen and (max-width: 670px) {
  .Blog-card {
    width: calc(90% - 20px);
  }
}

@media screen and (max-width: 425px) {
  .Blog-card {
    width: calc(100% - 20px);
  }

  .Blog-card-details {
    width: 80%;
  }

  .Blog-card-row {
    flex-direction: column;
  }
}

@media screen and (max-width: 375px) {
  .Blog-card {
    width: calc(100% - 20px);
  }

  .Blog-card-details {
    width: 90%;
  }

  .Blog-card-row {
    flex-direction: column;
    padding: 0;
  }
}

/*read more*/

.readmore {
  max-width: 1400px;
  margin: auto;
  padding: 3% 20px;
}

.readmore-heading {
  font-family: "Poppins", sans-serif;
  color: #064a93;
  text-align: center;
  font-size: 24px;
  line-height: 42px;
  margin: 20px 5%;
  text-align: center;
}

.readmore-text-container {
  padding: 20px 0px;
}

.readmore-paragraph {
  text-align: justify;
  font-family: "Poppins", sans-serif;
  margin: 20px;
  font-size: 18px;
  line-height: 32px;
  color: #333;
}

.readmore-container {
  width: 100%;
}

.readmore-one-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 3% 20px;
}

.readmore-one-image {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.readmore-one-content {
  width: 50%;
}

.readmore-section {
  width: 100%;
}

.readmore-section-title {
  text-align: justify;
  padding: 10px;
  font-family: "Poppins", sans-serif;
}

.readmore-section-body {
  padding: 10px;
  font-family: "Poppins", sans-serif;
}

.readmore-img-name {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.readmore-img-name1 {
  width: 100%;
  height: 350px;
  border-radius: 10px;
  margin: 10px;
}


@media (max-width: 768px) {
  .readmore-img-name {
    width: 100%;
    height: 400px;
    border-radius: 10px;
  }

  .readmore-one-container {
    flex-direction: column;
  }

  .readmore-one-image {
    width: 100%;
  }

  .readmore-one-content {
    width: 100%;
  }

  .readmore-heading {
    font-size: 24px;
    line-height: 38px;
  }

  .faq-container {
    max-width: 100% !important;
    margin: 0 auto;
    padding: 20px !important;
  }

  .faq-question {
    font-size: 16px !important;
  }
}

@media (max-width: 500px) {
  .readmore-img-name {
    width: 100%;
    height: 200px;
    border-radius: 10px;
  }
}

/*read review*/
.readmore-review {
  background-color: #115688;
  padding: 30px;
  border-radius: 10px;
  color: #fff;
  margin: 20px 0px;
}

.readmore-review-para {
  text-align: justify;
}

.readmore-review-Name {
  display: flex;
  justify-content: flex-end;
}



.readmore-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.coachingpage-three-icon {
  margin-right: 10px;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  color: #115688;
}

.readmore-list-li {
  display: flex;
  padding: 10px;
}

.readmore-two-container {
  padding: 20px;
}


.readmore-two-container h2 {
  font-size: 24px;
  line-height: 38px;
  color: #333;
  text-align: justify;
  font-weight: bold;
}

.readmore-two-container p {
  text-align: justify;
  font-size: 18px;
  line-height: 32px;
}

.free-immigration {
  display: flex;
}

.Readmore-free-immigration-assessment {
  padding: 20px 10%;
}

.Readmore-free-immigration-assessment h1 {
  text-align: left;
  padding: 10px;
}

.Readmore-free-immigration-assessment p {
  text-align: left;
  padding: 10px;
}

.replayform {
  display: grid;
  gap: 1rem;
}

.form-divider {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* gap: 1rem; */
}

.form-area {
  display: grid;
  align-items: initial;
  gap: 0.4rem;
  position: relative;
  width: 80%;
}

.form-area label {
  text-align: left;
  font-size: 0.8rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
}

.form-area input,
textarea {
  max-width: 100%;
  text-indent: 10px;
  height: 2.5rem;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  outline: none;
}

.form-area select {
  max-width: 100%;
  text-indent: 10px;
  height: 2.5rem;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  outline: none;
}

.form-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 2.5rem;
}

.error {
  color: red;
  font-size: 0.8rem;
  font-family: "Poppins", sans-serif;
  margin-top: 5px;
  margin: 0px;
  padding: 0px;
}

.form-button button {
  align-self: center;
  width: 100%;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin: 0px;
  padding: 1rem 2rem;
  background-color: #115688;
  color: #fff;
  border-style: none;
  outline: none;
  border-radius: 10px;
}

@media (max-width: 865px) {
  .readmore-list {
    flex-direction: column;
  }

  .readmore-two-container {
    padding: 0px 0px;
  }

  .readmore-list-li {
    padding: 0px;
  }

  .readmore-img-name {
    padding: 10px;
  }

  .Readmore-free-immigration-assessment h1,
  .Readmore-free-immigration-assessment p {
    text-align: justify;
    padding: 10px;
  }

  .readmore-two-container h2,
  .readmore-two-container p {
    text-align: center;
    padding: 10px;
  }
}

@media (max-width: 1024px) {
  .readmore-two-container {
    padding: 0px 0px;
  }
}

@media (max-width: 1450px) {
  .readmore-two-container {
    padding: 10px 10%;
  }

  .readmore-list-li {
    padding: 8px;
  }
}

@media (max-width: 1084px) {
  .readmore-two-container {
    padding: 10px 5%;
  }
}

@media (max-width: 1340px) {
  .card-details {
    width: 80%;
  }
}

@media (max-width: 1449px) {

  .readmore-two-container h2,
  .readmore-two-container p {
    /* text-align: center; */
    padding: 10px;
  }
}

@media (max-width: 345px) {
  .readmore-list-li {
    font-size: 14px;
  }
}


.free-immigration-assessment {
  width: 70%;
  margin: auto;
  background-color: #f9f9f9;
  padding: 5% 40px;
  border-radius: 8px;
}

.free-immigration {
  display: flex;
  gap: 10px;
}

.free-immigration-form {
  width: 50%;
}

.free-immigration-assessment h2 {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  color: #333;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
}

.form-row {
  margin-bottom: 15px;
  position: relative;
}

.form-row span {
  text-align: left;
  color: red;
}

.from_button {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
}

.from_button button {
  background-color: #064a93;
  color: #ffff;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin: 0px;
  padding: 0.5rem 1rem;
  border: 1px solid #064a93;
  outline: none;
  border-radius: 5px;
}

.from_blog {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
}

.form_button_blog {
  background-color: #064a93;
  color: #ffff;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin: 0px;
  padding: 0.5rem 1rem;
  border: 1px solid #064a93;
  outline: none;
  border-radius: 5px;
}


.free-immigration-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}


textarea {
  width: 100%;
  height: 100%;
  border: none;
  padding: 20px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

.card-footer-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.card-footer-button:hover {
  background-color: #064a93;
}

/* Media query for screen width 425px and below */
@media screen and (max-width: 425px) {
  .free-immigration {
    flex-direction: column;
  }

  .free-immigration-form {
    width: 100%;
  }
}

@media screen and (max-width: 760px) {
  .free-immigration {
    flex-direction: column;
  }

  .free-immigration-form {
    width: 100%;
  }

  .free-immigration-assessment {
    width: 90%;
    margin: 20px;
  }

  .Similarblogs-list-title {
    font-size: 24px;
    line-height: 38px;
  }


}

.faq-container {
  max-width: 80%;
  margin: 0 auto;
  padding: 6% 20px;
}

.faq-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.faq-item {
  border: 1px solid #ddd;
  border-radius: 20px;
  margin-bottom: 10px;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
}

.faq-arrow {
  margin-left: 10px;
}

.faq-question-arrow-icon {
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #064a93;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding: 0 15px;
}

.faq-answer.open {
  max-height: 100px;
  padding: 15px;
  border-top: 1px solid #ddd;
  line-height: 28px;
  font-size: 18px;
}

/*Blogs2*/

.admissions-guide-container {
  font-family: 'Poppins', sans-serif;
}

.tabs ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  border-bottom: 1px solid #ddd;
}

.tabs li {
  padding: 10px 20px;
  cursor: pointer;
}

.tabs li.active {
  border-bottom: 2px solid #115688;
  color: #115688;
  font-weight: bold;
}

.tab-content {
  padding: 20px 0;
}

.main-content {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.main-image {
  width: 300px;
  height: auto;
  margin-right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.text-content {
  max-width: 600px;
}

.text-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.text-content p {
  margin: 10px 0;
}

.quote-box {
  background: #115688;
  color: white;
  padding: 20px;
  margin-top: 20px;
  position: relative;
}

.quote-box:before {
  content: '\f1eb';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 2rem;
  position: absolute;
  top: 10px;
  left: 10px;
  opacity: 0.1;
}

.author {
  text-align: right;
  font-weight: bold;
}

/* Blogs.css */

.readmore-table-of-contents {
  margin: 20px auto;
  /* padding: 10px; */
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  width: 70%;
 
}
.toc-button{
  padding: 5px 10px;
}

.readmore-table-of-contents h4 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  background-color: #0056b3;
  padding: 10px;
}
.readmore-table-of-contents-list{
  text-align: justify;
  margin: 20px 40px;
}

.readmore-table-of-contents ol {
  margin: 0;
  text-align: justify;
  font-size: 18px;
  line-height: 32px;
  color: #333;
  text-align: justify;
}

.readmore-table-of-contents li {
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 32px;
  color: #333;
  text-align: justify;
}

.readmore-table-of-contents li a {
  text-decoration: none;
  color: #007bff;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.readmore-table-of-contents li a:hover {
  color: #0056b3;
  text-decoration: underline;
}

/*Similarblogs css*/
.Similarblogs-list {
  padding: 5% 20px;
}

.Similarblogs-list-title {
  font-size: 32px;
  line-height: 48px;
  color: #064a93;
  margin-bottom: 20px;
  text-align: center;
}

.Similarblogs-list-wrapper {
  position: relative;
}

.Similarblogs-list-container {
  display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding-bottom: 20px;
    margin: auto 8%;
    box-sizing: border-box;
}

.Similarblogs-card {
  /* background-color: white; */
  overflow: hidden;
  flex: 0 0 auto;
  width: 30%;
    margin-bottom: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    text-overflow: ellipsis;
    height: 510px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: auto 20px;
}

.Similarblogs-card-image {
  /* width: 100%;
  height: 200px;
  object-fit: cover; */

  width: 100%;
    height: 250px;
    display: block;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.Similarblogs-card-content {
  padding: 16px;
}

.Similarblogs-card-meta {
  display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: 70%;
    position: relative;
    top: -35px;
    background-color: #ffff;
    left: -16px;
    height: 50px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.Similarblogs-content {
  height: 110px;
}

.Similarblogs-card-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.2rem;
  margin: 12px 0;
  text-align: left;
}

.Similarblogs-card-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  color: #555;
  text-align: left;
}
.Similarblogs-content p:nth-child(2) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  color: #555;
  text-align: left;
}


.Similarblogs-card-read-more {
  color: #115688;
  display: flex;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 700;
  justify-content: flex-end;
  text-decoration: none;
  padding: 20px;
}

.Similarblogs-card-read-more:hover {
  text-decoration: underline;
}

.scroll-arrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: #115688;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-arrow:hover {
  background-color: #0e4d72;
}
/*search*/
 .search-container {
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 20px 5%;
}

.search-input {
  width: 600px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
  position: relative;
}

.search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 7px 20px;
  margin: 6px;
  border: none;
  background-color: #0EB614;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
  position: absolute;

}

.search-button:hover {
  background-color: #fff;
  border: 2px solid #0Eb614;
  color: #0EB614;
}


.No-similar-blogs-message {
  text-align: center; 
  font-size: 1.2rem; 
  color: #666; 
margin: auto;
}