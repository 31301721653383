.Login {
    display: flex;
    overflow: hidden;
  }
  
  .login-img-container {
    width: 40%;
  }
  
  .login-form {
    width: 60%;
    background-color: #F7FAFE;
    padding: 20px 10%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .login-form h1 {
    text-align: left;
    padding: 20px 0px;
    color: #115688;
  }
  
  .login-form h2 {
    text-align: left;
    padding: 10px 0px;
  }
  
  .forgot-container label {
    color: #333;
    font-weight: 400;
  }
  
  .login-form p {
    text-align: justify;
    padding: 10px 0px;
  }
  
  .login-img {
    width: 100%;
    height: 100vh; 
    object-fit: cover; 
  }
  @media screen and (max-width: 555px) {
    .login-img {
        height:50vh;
    }
  }
  
  
  .login-form-details {
    display: flex;
    flex-direction: column;
  }

  .label-form-input {
    width: 100%;
    padding: 12px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 14px;
    box-sizing: border-box;
    background-color: #F7FAFE;
  }
  
  .label-form-input {
    margin-right: 5px;
  }
  
  .label-form {
    font-size: 14px;
    text-align: left;
    color: #858687;
  }
  
  a{
    display: block;
    text-decoration: none;
    color: #ccc;
   }
  

  
  .login-button{
    background-color: #115688;
    color: #fff;
    border: none;
    border-radius: 14px;
    cursor: pointer;
    margin-top:10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-button svg {
    margin-left: 5px; 
  }
    

  
  .login-forgot {
    display: flex;
    justify-content: space-between; 
    align-items: center; 
  }
  
  .forgot-container {
    display: flex;
    align-items: center; 
  }
  
  .create-account {
    display: flex;
    align-items: center; 
    justify-content: center;
    padding: 20px;
  }
  
  @media (max-width: 1024px) {
    .login-img-container {
      height: 100vh; 
    }
    .verify-button {
  
      width: 95%;
      
    }
  }
  @media (max-width: 400px) {
   
    .label-form-input {
      width: 250px;
      margin-bottom: 0px;
    }
  }
  @media (max-width: 768px) {
   
    .label-form-input {
      width: 300px;
      margin-bottom: 0px;
    }
    .login-img-container {
      width: 50%;
    }
    .login-form {
      padding: 20px 5%;
  }
    .login-form {
      width: 50%;
      display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .login-forgot {
      flex-direction: column;
    }
    .create-account {
      flex-direction: column;
    }
  }
  
  @media (max-width: 767px) {
    .Login {
      flex-direction: column;
    }
    .login-img-container {
      width: 100%;
      height: auto;
    }
    .login-form {
      width: 100%;
    }
    .login-forgot {
      flex-direction: column;
    }
    .create-account {
      flex-direction: column;
    }
  }
  

  .forgotpassword{
    color: #607DFF;
  }



  

  .register-button{
    background-color: #115688;
    color: #fff;
    border: none;
    border-radius: 14px;
    cursor: pointer;
    width: 40%;
    margin-top:10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .register-button svg {
    margin-left: 5px; 
  }
    
  
  .register-button-container {
    display: flex;
    justify-content: flex-end; 
  }

  .register-form-details{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
  }

  .otp-input-container {
    display: flex;
    padding-top: 30px;
  }
  
  .otp-input {
    margin: 0 5px;
    font-size: 18px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    gap: 10px;
  }
  
  .otp-input:focus {
    outline: none;
    border-color: blue;
  }
  

  .verify-button{
    background-color: #115688;
    color: #fff;
    border: none;
    border-radius: 14px;
    cursor: pointer;
    width: 40%;
    margin-top:10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .verify-button svg {
    margin-left: 5px; 
  }
    
  
  .verify-button-container {
    display: flex;
    justify-content: center; 
    margin: 20px;
  }
.resend-code{
  display: flex;
  gap: 10px;
}
.resend-code-color{
  color: #115688;
}

.question-option{
  list-style-type: none;
  display: flex;
  gap: 10px;
}

.verifyquestions{
  display: flex;
  height: 100vh;
}
.verifyquestions-container {
  width: 30%;
  background-color: #fff;
  box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.1);
  text-align: left; 
  display: flex;
  flex-direction: column;
  /* justify-content: center;  */
  align-items: center; 
  padding-top: 80px;

}
.emailVerified{
  color: #858583;
}
.emailNotVerified{
  color: #115677;
}
.question-text{
  color: #115677;
  font-size: 20px;
  font-family: 500;
  padding: 10px;
}
.options-list{
  padding: 10px;  
  font-size: 16px;
  font-family: 400;
  color: #6F6F70;
}
.verifyquestions-container h1{
  color: #115677;
  padding-top: 20px;
  padding-bottom: 20px;
}

.verifyquestions-container-two{
  width: 70%;
  background-color: #f8f6ff;
  align-items: center; 
  text-align: left; 
  justify-content: center;
  display: flex;
}

.verifyquestions-container-ul {
  list-style: none;
  padding: 0;
  text-align: center; 
  padding: 20px;

}

.verifyquestions-container-ul li {
  display: flex;
  align-items: center; 
  margin-bottom: 10px;
}

.verifyquestions-container-ul input[type="checkbox"],
.verifyquestions-container-ul input[type="radio"] {
  margin-right: 10px;
}

.verifyquestions-container label {
  margin: 0;
}


