/*banner*/
.About-Img-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;
  padding-top: 40px;
  padding-bottom: 20px;
  background: url(../../assets/about.png) right bottom/cover no-repeat;
  height: 460px;
}

.About-container-img-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.About-hero-text {
  position: absolute;
  top: 35%;
  left: 15%;
  transform: translate(-40%, -40%);
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.About-hero-text h1 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 800;
  background: #fff;
  background-clip: text;
  color: transparent;
}


.About-hero-text p {
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  color: #fff;
  padding: 0.5rem 0 2rem 0;

}

.About-Home-link {
  color: #fff;
}

.About-Home-link-span {
  color: #0073e6;
  font-weight: 600;
}


@media screen and (max-width: 768px) {
  .About-hero-text p {
    font-size: 1.1rem;
  }

  .About-hero-text h1 {
    font-size: 2rem;
    font-weight: 800;
    background: #fff;
    background-clip: text;
    color: transparent;
  }

  .About-hero-text {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-height: 555px) {
  .About-hero-text {
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 500px) {
  .About-hero-text {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


@media screen and (max-width: 555px) {
  .About-Img-container {
    height: 300px;
  }
  .About-hero-text {
    top: 33%;
  }
}

/*about*/
.about-us-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.about-one {
  background-color: #F7FAFE;
  padding: 10% 5%;
  margin: auto;
}

.about-text-section {
  flex-basis: 50%;

}

.about-one-heading {
  text-align: left;
  color: #858583;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.about-one-heading-large {
  text-align: left;
  color: #115688;
  font-size: 32px;
  line-height: 42px;
  font-family: "Poppins", sans-serif;
  padding: 10px 10px 10px 0px;
}
.Home-link{
  color: #fff;
}

.Home-link-span{
  color: #0073e6;
  font-weight: 600;
}
.about-heading {
  text-align: left;
  color: #858583;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  text-decoration: underline;
}

.about-one-para {
  text-align: justify;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 30px;

}

.About-one-image-section {
  flex-basis: 50%;
}

.About-one-image-section img {
  width: 80%;
  height: auto;
  border-radius: 10px;
}


@media screen and (max-width: 768px) {
  .about-us-container {
    flex-direction: column;
  }

  .About-one-image-section,
  .about-text-section {
    width: 100%;

  }

  .about-one {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 20px;

  }

  .about-one-heading,
  .about-one-heading-large,
  .about-heading,
  .about-one-para {
    text-align: center;
  }

}

.about-two-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 5% 20px;

}

.about-two-item {
  width: 30%;
  padding: 20px;
}

.numberimg {
  width: auto;
  height: 30px;
  display: flex;
  align-items: flex-start;
}

.about-two-heading {
  text-align: left;
  font-size: 32px;
  font-family: "Poppins", sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
}

.about-two-paragraph {
  text-align: justify;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 30px;
}

@media screen and (max-width: 768px) {
  .about-two-item {
    width: 100%;
  }
}

/*react count*/
.statistics-container {
  background-image: url('../../assets/countupimg.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 50px;
}

.statistics-container img {
  opacity: 0.5;
  border: 5px solid #115688;
  max-width: 100%;
  height: auto;
}
.About-number-container{
  font-size: 48px;
  color: #ddd;
  font-weight: bold;
  text-align: left;
}
.statistic {
  text-align: center;
  font-size: 1.8rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  padding: 20px;
}

.statistic div {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
}

.teachers-section {
  text-align: center;
  padding: 20px;
  background: #f9f9f9;
}

.teachers-section h2 {
  color: #115688;
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  text-align: left;
  padding: 10px 18%;
}

.teacher-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: auto;
}

.teacher-card-item {
  width: calc(33.33% - 20px);
  margin: 10px;
  text-align: center;
}

.teacher-card {
  margin: 10px;
  text-align: center;
}

.teacher-card-item img {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.teacher-heading-small {
  color: #858583;
  text-decoration: underline;
  font-size: 1.3rem;
  font-family: "Poppins", sans-serif;
  text-align: left;
  padding: 10px 18%;
}

.teacher-card p {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #666;
}

.teacher-heading {
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  color: #333;
}


.teacher-heading-small {
  font-size: 18px;
  line-height: 30px;
  font-family: "Poppins", sans-serif;
  color: #888;
}

.teacher-heading {
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
}

.teacher-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.teacher-card-item {
  width: 300px;
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  /* border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

/* .teacher-card-item img {
  width: 250px;
  height: 25  0px;
  border-radius: 50%;
  margin-bottom: 10px;
} */

.teacher-card-item h3 {
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  color: #333;
}

.teacher-card-item p {
  margin-top: 5px;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  color: #666;
}

.teacher-card-content {
  margin-top: 10px;
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  color: #777;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .statistics-container {
    flex-direction: column;
    text-align: center;
  }

  .statistics-container img {
    margin-bottom: 20px;
  }

  .About-one-image-section img {
    width: 100%;
    padding: 10px;
  }

  .teacher-cards {
    flex-direction: column;
  }

  .teacher-card-item {
    width: 280px;
    margin: 0px;
  }

  .teacher-cards {
    padding: 0px 0%;
    align-items: center;
  }

  .teacher-heading-small {
    text-align: center;
    padding: 10px 0%;
  }

  .teachers-section h2 {

    text-align: center;
    padding: 10px 0%;
  }
}