/*pagination*/
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
  margin-top: 20px;
}

.pagination li {
  margin: 0 5px;
  cursor: pointer;
}

.pagination li a {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  text-decoration: none;
}

.pagination li.active a {
  background-color: #115277;
  color: #fff;
  border-color: #115277;
}

/* Home.css */
.Veteach-home {
  overflow-x: hidden;
}

.slider {
  width: 100%;
  height: 600px;
  overflow: hidden;
}

.home_slider_wrapper {
  position: relative;
}

.home_slider_wrapper>img {
  width: 100%;
  height: 70vh;
  object-fit: cover;

}

.slide {
  position: relative;
  width: 100%;
  height: 100%;
}

.rmsc .dropdown-heading {
  border: none !important;
  background-color: #f7f8f9 !important;
}

.rmsc .dropdown-container {
  background-color: var(--rmsc-bg);
  border: none !important;
  border-radius: var(--rmsc-radius);
}

.awssld__bullets {
  position: absolute;
  /* bottom: -40px; */
  top: -150px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.awssld__content>img,
.awssld__content>video {
  object-fit: cover;
  width: 100%;
  height: 700px !important;
  position: absolute;
  top: 0;
  left: 0;
}

.Home-Img-container,
.Main-container-img-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Home-hero-text {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -35%);
  width: 40%;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: #fff;
}


.Home-hero-text h1 {
  font-size: 32px;
  line-height: 42px;
}

.Home-hero-text p {
  font-size: 18px;
  line-height: 30px;
}

@media screen and (max-width: 768px) {
  .slider {
    height: 400px;
  }

  .Home-hero-text {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
  }

  .Home-hero-text h1 {
    font-size: 24px !important;
    font-family: "Poppins", sans-serif;
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
  }

  .Home-hero-text p {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.awssld__box {
  height: 650px !important;
}

.awssld__bullets {
  bottom: -350px !important;
}

/* Home.css */
.Home-content-button {
  width: 50%;
}

.Home-view-button {
  width: 50%;
}

.search-container-inside {
  position: relative;
  top: -60px;
  font-family: "Poppins", sans-serif;
  background: #ffffff;
  border: 0.5px solid rgba(220, 227, 231, 0.35);
  border-radius: 8px;
  opacity: 1;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
  /* margin-bottom: 60px; */
  width: 80%;
  z-index: 2;
  transition: top 0.3s ease;
}

/* Adjust top position when advanced search is open */
.search-container-inside.advanced-search-open {
  top: -180px;
}

/* Adjust top position when advanced search is closed */
.search-container-inside.advanced-search-closed {
  top: -180px;
}

@media screen and (max-width: 1496px) {
  .search-container-inside.advanced-search-open {
    top: -270px;
  }

  /* Adjust top position when advanced search is closed */
  .search-container-inside.advanced-search-closed {
    top: -270px;
  }

  .Home-hero-text h1 {
    font-size: 28px;
    line-height: 42px;
  }

  .search-container-inside {
    width: 95%;
  }

}

.search-inbox {
  height: 40px;
  width: 200px;
  background: #f7f8f9 0% 0% no-repeat padding-box;
  border: 1px solid rgb(220 227 231 / 35%);
  border-radius: 4px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
}

.search-inbox2 {
  height: 40px;
  width: 200px;
  background: #f7f8f9 0% 0% no-repeat padding-box;
  border: 1px solid rgb(220 227 231 / 35%);
  border-radius: 4px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  /* padding: 10px; */
}

.search-states-list {
  position: absolute;
  width: 200px;
  background: #f7f8f9;
  border: 1px solid rgba(220, 227, 231, 0.35);
  border-radius: 4px;
  text-align: left;
  padding: 20px 20px 20px 30px;
  font-family: "Poppins", sans-serif;
  z-index: 10;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  height: 300px;
  overflow-y: auto;
}

.search-advance {
  padding: 8px 24px;
  background: #ffffff;
  color: #947dde;
  border-radius: 8px;
  border: 1px solid #947dde;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 27px;
  box-shadow: 0 0 0 0;
}

.search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 24px;
  margin-top: 20px;
  height: 40px;
  width: 200px;
  background-color: #115277;
  color: #fff;
  border-radius: 20px;
  border: none;
  font-size: 18px;
}

@media only screen and (max-width: 1067px) {
  .search-inbox {
    height: 40px;
    width: 200px;
    background: #f7f8f9 0% 0% no-repeat padding-box;
    border: 1px solid rgb(220 227 231 / 35%);
    border-radius: 4px;
    padding: 10px;
    font-family: "Poppins", sans-serif;
  }

  .search-button {
    height: 40px;
    width: 200px;
  }
}

.college-list {
  position: relative;
  top: -160px;
  text-align: center;
  height: 400px;
}

@media screen and (max-width: 1088px) {
  .college-list {
    position: relative;
    top: -200px;
    text-align: center;
    height: 700px;
  }
}

@media screen and (max-width: 768px) {
  .search-container-inside {
    position: initial;
  }

  .search-container {
    height: 100%;
  }

  .college-list {
    position: relative;
    top: -200px;
    text-align: center;
    height: 1000px;
  }
}

@media screen and (max-width: 500px) {
  .college-list {
    height: 1300px;
  }
}

.college-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.college-image {
  width: 350px;
  height: auto;
}

.college-list-heading {
  padding: 20px;
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  line-height: 42px;
  color: #074776;
}

/* Media query for mobile devices */
@media only screen and (max-width: 768px) {
  .search-container-inside {
    width: 90%;
    padding: 0 10px;
    top: -60px;
    flex-direction: column;
  }

  .college-list {
    padding: 20px;
    top: 0px;
  }

  .college-image {
    width: 100%;
    height: 275px;
  }

  .college-list-heading {
    padding: 10px;
  }
}

/*AdvanceSearch*/
.advanced-requirements {
  color: #333;
  font-weight: 400;
  padding: 20px;
  text-align: left;
  width: 30%;
}

/*Home-about*/
.Home-about {
  background-color: #f7fafe;
  padding: 10% 20px;
}


.Home-about-box {
  display: flex;
  flex-wrap: wrap;
  padding-left: 50px;
  padding-right: 50px;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.Home-about-Container1 {
  width: 40%;
  text-align: left;
  color: #115688;
}

.Home-about-Container2 {
  padding: 20px;
  display: flex;
  width: 40%;
  align-items: flex-start;
}

.Home-about1-img {
  border: 1px solid #000000;
  max-width: 100%;
  height: 200px;
}

.Home-about2-img {
  position: relative;
  top: 85px;
  border: 1px solid #000000;
  z-index: 2;
  max-width: 100%;
  height: 200px;
  left: -80px;
}

.Home-about-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  color: #115688;
  text-align: center;
}


.Home-about-heading h1 {
  flex: 1;
  align-items: center;
  color: #115688;
}

.blog_bt {
  padding: 10px 30px;
  background-color: #115688;
  color: #fff;
  border-radius: 30px;
  border: none;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.blog_bt:hover {
  color: #ffff;
  background-color: #074776;
}

.Home-about-large-heading {
  font-size: 26px;
  line-height: 42px;
  color: #074776;
  font-family: "Poppins", sans-serif;
  padding: 5px;
}

.Home-about-small-heading {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  padding: 5px;
}

.Home-about-large-paragraph {
  font-size: 18px;
  line-height: 30px;
  font-family: "Poppins", sans-serif;
  padding: 5px;
}

@media screen and (max-width: 1024px) {

  .Home-about1-img,
  .Home-about2-img {
    height: 170px;
  }

  .Home-about-box {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .Home-about-box {
    flex-direction: column;
  }

  .Home-about-box {
    justify-content: center;
  }

  .Home-about1-img {
    display: block;
    left: -280px;
    top: 180px;
  }

  .Home-about2-img {
    display: none;
  }

  .Home-about-box {
    padding-left: 0px;
    padding-right: 0px;
  }

  .Home-about-Container1 {
    width: 100%;
  }

  .Home-about-Container2 {
    width: 100%;
  }

  .Home-about1-img {
    margin: auto;
  }

  .Home-about-Container1 {
    text-align: center;
    color: #115688;
  }

  .Home-about {
    background-color: #f7fafe;
  }
  .Home-about-heading {
    flex-direction: column;
    
  }
  
}

@media screen and (max-width: 320px) {
  .Home-about {
    background-color: #f7fafe;
  }
}

/*examtypes*/
.Home-course-container {
  padding: 5% 20px;
}

.exam-item {
  width: 180px;
  height: 138px;
  border: 2px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.exam-image {
  width: 77px;
  height: 50px;
}

.exam-name {
  padding: 20px;
}

.exam-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  gap: 20px;
}

/*exam-assessment*/
.Exam-assessment {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 5% 20px;
  background-color: #115688;
  color: #fff;
  align-items: center;
}

.Exam-assessment-container {
  flex: 1;
  text-align: center;
}

.Exam-assessment-container1 {
  margin-right: 5px;
}

.Exam-assessment-container2 {
  margin-left: 5px;
}

.Exam-assessment-button {
  padding: 10px 30px;
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  background-color: #115688;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  border: 2px solid #ccc;
}

.Exam-assessment-heading-large {
  font-size: 24px;
  line-height: 30px;
  font-family: "Poppins", sans-serif;
  padding: 5px;
  text-align: left;
}

.Exam-assessment-heading-small {
  font-size: 18px;
  line-height: 30px;
  font-family: "Poppins", sans-serif;
  padding: 5px;
  text-align: left;
}

.Exam-assessment-button-icon {
  margin-left: 5px;
}

@media only screen and (max-width: 1024px) {
  .Exam-assessment {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .Exam-assessment-heading-large,
  .Exam-assessment-heading-small {
    text-align: center;
  }

}

@media only screen and (max-width: 768px) {
  .Exam-assessment {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .Exam-assessment-container1,
  .Exam-assessment-container2 {
    margin: 0;
  }

  .Exam-assessment-heading-large,
  .Exam-assessment-heading-small {
    text-align: center;
  }
}

/*review*/


.slick-dots li button:before {
  font-size: 15px !important;
  font-family: "Poppins", sans-serif;
}

.slick-dots li.slick-active button:before {
  color: #115688 !important;
}

.slick-next:before,
.slick-prev:before {
  color: #115688 !important;
}

.slick-next,
.slick-prev {
  padding: 40px !important;
  top: 30% !important;
}

.slick-list {
  margin: auto 10px !important;
  max-width: 1200px !important;
}

.review-item {
  padding: 10px;
}


.Resource-Blogs-review-items {
  padding: 10px;
}

.review-imgs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
  border: 2px solid #ffff;
  border-radius: 10px;
  margin: 0px;
  padding: 0.5rem;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 300px;
  width: 250px;
}
.review-name-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 270px;
}

.review-name {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #000;
}

.review-icon {
  color: #115688;
}

.review-rating {
  color: #115688;
}

.review-img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 20px auto;
  object-fit: cover;
  overflow: hidden;
}

.review-imges {
  width: 6rem;
  margin: 0px;
}


.review-para {
  width: 270px;
  margin: auto;
  font-family: "Poppins", sans-serif;
  padding: 10px 0px;
  text-align: justify;
  line-height: 1.6;
  font-size: 16px;
}

.rotate-180 {
  transform: rotate(180deg);
}

.review-container {
  margin: 0 auto;
  padding: 8% 20px;
  font-family: Arial, Helvetica, sans-serif;
  box-sizing: border-box;
  width: 100%;
  color: rgb(52, 48, 48);
  background-color: #f7fafe;
}



.Resource-Blogs-review-images {
  width: 180px;
  height: 250px;
}

.Resource-Blogs-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 10% 20px;
  box-sizing: border-box;
  width: 100%;
  color: rgb(52, 48, 48);
  background-color: transparent;
}

.blogs_header {
  display: flex;
  flex-direction: column;
  padding-bottom: 3%;
  align-items: center;
  justify-content: center;
}

.blogs_header h1 {
  font-size: 32px;
  line-height: 42px;
  color: #074776;
  font-family: "Poppins", sans-serif;
  margin: 0px;
}

.Resource-Blogs-review-imgs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
  border: 2px solid #ffff;
  border-radius: 10px;
  margin: 0px;
  padding: 20px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 300px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
}

.blogs_header p {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  color: #2a2d2d;
  font-weight: 500;
  margin: 0px;
}

@media only screen and (max-width: 320px) {

  .review-img{
    width: 200px;
  }
  .review-para {
    width: 270px;
  }

  .slick-slide img {
    display: block;
  }
}

/*Blog*/
/* 
.blog-carousel {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
  padding: 10% 20px;
  text-align: center;
  background-color: #F7FAFF;
}


.blog-scroll {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.blog-card {
  flex: 0 0 auto;
  width: 330px;
  transform: rotateY(-10deg);
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.blog-card:hover {
  transform: rotateY(0deg);
}

.blog-image {
  height: 200px;
  background-color: #ccc;
}

.blog-img {
  width: 100%;
  height: auto;
}

.blog-content {
  padding: 8% 20px;
  background-color: #FFF;
}

.blog-info span {
  font-size: 0.8rem;
  font-family: "Poppins", sans-serif;
  color: #777;
  margin-bottom: 5px;
}

.blog-title {
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 10px;
}

.blog-description {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 10px;
}

.blog-read-more {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 18px;
} */
/*Img and text on it */
.Blogs-Img-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;
  padding-top: 40px;
  padding-bottom: 20px;
  background-image: url(../../assets/freeresources.png);
  background-color: transparent;
  background-position: right bottom;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  height: 460px;
}

.Blog-hero-text {
  position: absolute;
  top: 30%;
  left: 15%;
  transform: translate(-40%, -40%);
  width: 100%;
}

.Blog-hero-text h1 {
  font-size: 32px;
  line-height: 42px;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  background: #fff;
  background-clip: text;
  color: transparent;
}

.Blog-hero-text p {
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  font-family: "Poppins", sans-serif;
  color: #fff;
  padding: 0.5rem 0 2rem 0;
}

@media screen and (max-width: 480px) {
  .Blog-hero-text p {
    font-size: 1.1rem;
    color: #fff;
    padding: 0 0 2rem 0;
  }

  .Blog-hero-text {
    position: absolute;
    top: 30% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .Blogs-Img-container {
    height: 300px;
  }
}

@media screen and (max-width: 768px) {
  .Blog-hero-text p {
    font-size: 1.3rem;
    color: #fff;
    padding: 0 0 2rem 0;
  }

  .Blog-hero-text {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .Blog-hero-text h1 {
    font-size: 2.5rem;
    font-weight: 800;
    background: #fff;
    background-clip: text;
    color: transparent;
  }
}

.Blog-card-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.free-immigration-assessment-header {
  padding-top: 10px;
  padding-bottom: 10px;
}

.free-immigration-assessment-header p {
  text-align: left;
}

.Blog-card {
  width: 30%;
  margin-bottom: 20px;
}

.Blog-card-image img {
  width: 100%;
  height: auto;
}

.Blog-card-content {
  padding: 10px;
  background: #fff;
}

.Blog-card-details {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.Blog-card-title-des {
  margin-bottom: 10px;
}

.Blog-card-read-more {
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
}

.Blog-card-icon {
  margin-right: 5px;
}


.Blog-content {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
}


.readmore-hero-text {
  position: absolute;
  top: 30%;
  left: 15%;
  transform: translate(-40%, -40%);
  width: 100%;
}

.readmore-hero-text h1 {
  font-size: 3rem;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  background: #fff;
  background-clip: text;
  color: transparent;
}

.readmore-hero-text p {
  font-size: 1.3rem;
  font-family: "Poppins", sans-serif;
  color: #fff;
  padding: 0.5rem 0 2rem 0;
}

@media screen and (max-width: 555px) {
  .readmore-hero-text p {
    font-size: 1.1rem;
    color: #fff;
    padding: 0 0 2rem 0;
  }

  .readmore-hero-text {
    position: absolute;
    top: 30% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .Blogs-Img-container {
    height: 300px;
  }
}

@media screen and (max-width: 768px) {
  .readmore-hero-text p {
    font-size: 1.3rem;
    color: #fff;
    padding: 0 0 2rem 0;
  }

  .readmore-hero-text {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .readmore-hero-text h1 {
    font-size: 2.5rem;
    font-weight: 800;
    background: #fff;
    background-clip: text;
    color: transparent;
  }
}

/*Blogs*/

.Blog-card-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 40px 10%;
}

.Blog-card {
  width: calc(30% - 20px);
  margin-bottom: 20px;
  box-sizing: border-box;
  /* border: 2px solid #ccc; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-overflow: ellipsis;
  height: 510px;
}



.Blog-card-image img {
  width: 100%;
  height: 250px;
  display: block;
  border-radius: 10px;
}

.Blog-card-content {
  padding: 16px;
}

.Blog-card-title-des {
  height: 120px;
}

.Blog-card-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid #ccc; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 70%;
  position: relative;
  top: -35px;
  background-color: #ffff;
  left: -16px;
  height: 50px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.Blog-card-icon {
  color: #115688;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

.Blog-card-author,
.Blog-card-date {
  font-size: 0.8rem;
  font-family: "Poppins", sans-serif;
  display: flex;
  color: #858583;
}

.Blog-card-title {
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  margin-bottom: 3px;
  color: #115688;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.Blog-card-description {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  color: #858583;
  margin-bottom: 16px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.Blog-card-read-more {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: #115688;
  text-decoration: none;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
}

.Blog-card-read-more:hover {
  text-decoration: underline;
}

.Blog-card-read-more svg {
  margin-left: 5px;
  margin-top: 4px;
}

/* Responsive styles */

@media screen and (max-width: 1024px) {
  .Blog-card {
    width: calc(40% - 20px);
  }

  .Blog-card-details {
    width: 80%;
  }
  .Blog-card-row {
    padding: 20px 5%;
  }
}

@media screen and (max-width: 768px) {
  .Blog-card {
    width: calc(50% - 20px);
  }
}

@media screen and (max-width: 670px) {
  .Blog-card {
    width: calc(90% - 20px);
  }
}

@media screen and (max-width: 425px) {
  .Blog-card {
    width: calc(100% - 20px);
  }

  .Blog-card-details {
    width: 80%;
  }

  .Blog-card-row {
    flex-direction: column;
  }

  .Blog-card-row {
    padding: 20px;
  }
}

@media screen and (max-width: 375px) {
  .Blog-card {
    width: calc(100% - 20px);
  }

  .Blog-card-details {
    width: 90%;
  }

  .Blog-card-row {
    flex-direction: column;
    padding: 0;
  }
}

/* search container */

.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.advanced-search-button {
  margin-top: 10px;
}

.search-container-main {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.search-container-one {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px;
  gap: 20px;
}

.search-container-two {
  margin: 10px;
}

/*advance search*/

@media screen and (max-width: 1026px) {
  .search-container-inside {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .search-container-inside {
    width: 90%;
  }

  .advanced-search-options {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 0px;
    width: 100%;
  }

  .advanced-program-level,
  .advanced-requirements,
  .advanced-duration,
  .advanced-Country {
    width: 100% !important;
    text-align: center;
    padding-left: 0px;
  }
}

/* Base styles */
.advanced-search-options {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.advanced-search-options h3 {
  color: #333;
  padding-top: 5px;
  padding-bottom: 5px;
}

.advanced-program-level {
  width: 30%;
  text-align: left;
  padding: 20px;
}

.advanced-program-level label {
  padding-top: 5px;
  margin-top: 5px;
  color: #333;
  font-weight: 400;
}

.search-container-one label {
  color: #333;
}

.advanced-program-level input {
  margin-right: 5px;
  height: 15px;
  width: 15px;
}

.advanced-requirements input {
  margin-right: 5px;
  height: 15px;
  width: 15px;
}

.advanced-requirements {
  text-align: left;
  width: 30%;
  padding: 20px;
  color: #333;
  font-weight: 400;
}

.advanced-requirements label {
  padding-top: 5px;
  margin-top: 5px;
  color: #333;
  font-weight: 400;
}

.advanced-duration {
  width: 30%;
  text-align: left;
  color: #333;
  font-weight: 400;
}

.advanced-Country {
  width: 30%;
  color: #333;
  font-weight: 400;
  padding: 20px;
  text-align: left;
}

.select-country {
  width: 180px;
  height: 35px;
  text-align: left;
}

/*university.css*/

.card-list {
  display: flex;
  justify-content: space-between;
}

.college-card {
  width: 350px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.college-card-image {
  width: 100%;
  height: auto;
}

.college-card-content {
  padding: 16px;
}

.college-card-title {
  margin: 0;
  font-size: 24px;
  line-height: 40px;
  color: #074776;
  text-align: left;
  font-family: "Poppins", sans-serif;
}

.college-card-location {
  display: flex;
  align-items: center;
  font-size: 16px;
  text-align: left;
  font-family: "Poppins", sans-serif;
}

.college-location-icon:before {
  content: "📍";
  margin-right: 8px;
}

.college-bookmark-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
}

.college-bookmark-icon:before {
  content: "🔖";
}

.view-all-button-container {
  margin: 20px;
}

.view-all-button {
  padding: 10px 30px;
  background-color: #115688;
  color: #fff;
  border-radius: 30px;
  border: none;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.testimonials-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 90%;
  margin: auto;
  gap: 20px;

}

.testimonials-header h1 {
  text-align: center;
  font-size: 32px;
  line-height: 42px;
  color: #074776;
  font-family: "Poppins", sans-serif;
}

.Home_course_h1 {
  text-align: center;
  padding: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  line-height: 42px;
  color: #074776;
}



.slide {
  background-size: cover;
  background-position: center;
  height: 650px;
}

.slider-container .slick-slide {
  background-color: rgba(255, 255, 255, 0.8);
}



@media (max-width: 768px) {
  .testimonials-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  .slider-container {
    padding: 0 10px;
  }

  .slider-container .slick-slide {
    height: auto !important;
  }

  .slider-container .slick-slide img {
    width: 100%;
    height: auto;
  }

  .slider-container .slick-prev,
  .slider-container .slick-next {
    display: none;
  }
}

@media (max-width: 500px) {
  .college-card-title {
    margin: 0;
    font-size: 18px;
    line-height: 40px;
    color: #074776;
    text-align: left;
    font-family: "Poppins", sans-serif;
  }
}


/*Resources Blogs Details*/

.admissions-guide-container {
  font-family: 'Poppins', sans-serif;
  padding: 20px;
}

.navbar-container {
  background-color: #064a93;
  height: 100px;
}

.ResourceBlog-resourceName {
  font-size: 32px;
  line-height: 48px;
}

.ResourceBlog-main-one {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3% 20px;
  margin: 20px auto;
  gap: 20px;
}

.ResourceBlog-main-one-img {
  width: 50%;
}

.ResourceBlog-main-one-content {
  width: 50%;
}


.ResourceBlog-main-one-content h3 {
  font-size: 24px;
  line-height: 38px;
  margin: 0 0 10px 0;
  text-align: justify;
}

.ResourceBlog-main-one-content p {
  font-size: 18px;
  line-height: 32px;
  color: #333;
  text-align: justify;
}

.ResourceBlogs-image {
  max-width: 100%;
  height: auto;
}

.ResourceBlog-main-one {
  padding: 3%;
}

.ResourceBlog-main-two {
  margin: 5% 20px;
}

.ResourceBlogs-two-image {
  max-width: 100%;
  height: auto;
}

.ResourceBlog-main-two-content {
  margin: 3% 20px;
}

.ResourceBlog-main-two-content h3 {
  font-size: 24px;
  line-height: 38px;
  margin: 0 0 10px 0;
  text-align: justify;
}

.ResourceBlog-main-two-content p {
  font-size: 18px;
  line-height: 32px;
  color: #333;
  text-align: justify;
}

.Resourcesblog-section-image {
  padding: 3% 20px;
  max-width: 800px;
  height: auto;
}

/* Tabs */
.ResourceBlog-tabs {
  display: flex;
  list-style: none;
  margin: 20px;
  border-bottom: 3px solid #333;
}

.ResourceBlog-tabs ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 24px;
  line-height: 38px;
  color: #064a93;
  font-weight: 700;
}

.ResourceBlog-tabs li {
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s;
  font-size: 20px;
  line-height: 38px;
}

.ResourceBlog-tabs li.active {
  border-bottom: 2px solid #000;
}

.ResourceBlog-tabs li:hover {
  background-color: #f0f0f0;
}

@media (max-width: 425px) {
  .ResourceBlog-tabs {
    flex-direction: row;
    overflow-x: auto;
  }

  .ResourceBlog-tabs li {
    flex: 0 0 auto;
    padding: 10px;
  }
}


/* FAQ Section */
.faq-container {
  padding: 5% 20px;
  max-width: 1200px;
  margin: 20px auto;
}

.faq-container-heading {
  font-size: 32px;
  line-height: 48px;
  color: #064a93;
  padding-bottom: 30px;
}

.faq-item {
  margin-bottom: 15px;
  font-size: 16px;
  cursor: pointer;
  padding: 10px;
  max-width: 1200px;
  border: 1px solid #ddd;
  border-radius: 20px;
  margin-bottom: 10px;
}

.faq-question {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-answer {
  display: none;
  padding-top: 10px;
  font-size: 18px;
  line-height: 32px;
}

.faq-answer.open {
  display: block;
}

.faq-question-arrow-icon {
  font-size: 30px;
  color: #074776;
}

/* Form Section */
.free-immigration-assessment {
  max-width: 900px;
  margin: 20px auto;
  background-color: #f9f9f9;
  padding: 5% 40px;
  border-radius: 8px;
}

.free-immigration-assessment h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  text-align: left;
}

.free-immigration-form {
  display: grid;
  gap: 1rem;
}


.form-row {
  flex: 1 1 calc(50% - 1rem);
  min-width: 250px;
}

.form-row {
  display: flex;
  flex-direction: column;
}

.free-immigration-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.form-button {
  display: flex;
  justify-content: center;
}

.form-button button {
  padding: 10px 20px;
  background-color: #115688;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-button button:hover {
  background-color: #0a3b5a;
}



.free-immigration-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.form-row {
  flex: 1 1 calc(50% - 1rem);
  min-width: 250px;
}

.free-immigration-input {
  width: 100%;
}

@media (max-width: 768px) {
  .form-row {
    flex: 1 1 100%;
  }
}

@media (max-width: 425px) {
  .form-row {
    flex: 1 1 100%;
  }
}



/* Media Queries */
@media (max-width: 768px) {
  .ResourceBlog-main-one {
    flex-direction: column;
  }

  .ResourceBlog-main-one-img,
  .ResourceBlog-main-one-content {
    width: 100%;
  }

}

@media (max-width: 480px) {
  .ResourceBlog-main-one-content h2 {
    font-size: 1.5rem;
  }

  .ResourceBlog-main-one-content p {
    font-size: 0.9rem;
  }
}

