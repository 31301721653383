.Advance-Search-page{
    width: 100%;
    display: flex;
    gap:10px;
    padding: 20px;
}
.Advance-Search-filter,.Advance-Search-Coursesfound{
    width: 30%;
    margin: 20px;
  
}
.Advance-Search-Coursesfound{
    width: 70%;
    max-height: 1850px;
    overflow-y: auto;
}

#sticky-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white; 
  }

.Advance-Home-filt-heading{
    display: flex;
    justify-content:left;
    align-items: center;
    gap:10px;
    font-size: 24px;
    padding: 10px;
}
.Advance-Home-filt-h3{
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
    color: #fff;
    background-color: #115677;
    font-size: 16px;
    margin-bottom: 10px;
}
.Advance-Home-filter-Content{
    background-color: #FAFBFC;
    padding: 20px;
}
.Advance-Home-label{
    text-align: left;
    font-size: 14px;
    padding-top: 10px;
}
.Advance-Home-select{
    width: 100%;
    padding: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
}
.country-list{
    height: 300px;
}
.Advance-Home-select-student{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
     border: 1px solid #ccc;
    border-radius: 5px;
    color: #999;
   position: relative;
   gap: 5px;

   background-color: #fff;
}

.dropdown-content{
    height: 90px;
    width: 100%;
    padding: 1px;
    background-color: #fff;
    position: absolute;
    z-index: 1;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
}
.student-register-button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    background-color: #115677;
    color: #fff;
    margin-top: 40px;
    padding: 10px;
}
.Advance-Home-select-score{
    width: 40%;
    padding: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
   display: flex;
   align-items: flex-start;
}
.advance-filter-button{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;

}
.advance-filter-button-one{
    background-color: #115677;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin: 10px;
}
.advance-filter-universities{
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.advance-filter-universities-header{
   background-color: #115277;
   color: #fff;
   border: 1px solid #ccc;

}


.advance-filter-universities-header {
    margin-bottom: 10px;
    padding: 10px;
}

.university-list input[type="checkbox"] {
    margin-right: 10px;
}
.advance-filter-university-list{
    padding: 10px;

}
.university-list label {
    margin-right: 20px; 
}

.advance-filter-names {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
}
.advance-filter-checkbox-name{
    display: flex;
    gap: 10px;
    width: 80%;
}
.advance-filter-textnumber{
    display: flex;
    align-items: flex-end;
    width: 20%;
}




/*Course found*/
.Advancesearch-main-heading,.Advancesearch-main-view{
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 800;
    padding: 10px;
}
.Advancesearch-main-view{
    font-size: 15px;
    font-weight: 500;
    gap:5px;
    color: #115277;
    padding: 10px;
    margin: 10px;
    justify-content: center;
    border-radius: 20px;
    text-decoration: underline;
}
.Advancesearch-shortlist-buttons{
    padding: 10px;
    display: flex;
}
.Advancesearch-shortlist-heading{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    gap: 5px;
    margin: 5px;
}
.Advancesearch-heading-icon-switch{
    color: #fff;
    font-size: 20px;
    font-weight: 600;
}
.Advancesearch-heading-icon-view{
    font-size: 20px;
    font-weight: 600;
}
.Advance-Course-found{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding: 12px;
}
.Advance-Home-Course-heading{
    display: flex;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding: 10px;
    color: #fff;
    background-color: #115677;
    font-size: 16px;
    margin-bottom: 10px;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
}
.Advancesearch-heading{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap:5px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    padding: 5px;
    width: 100%;
}
.Advance-Search-university-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #ccc;
    padding: 0px;
    text-align: left;
}

.Advancesearch-heading-answer{
    color: #999;
}
.Advancesearch-heading-icon{
    color: #115677;
    font-size: 20px;
    font-weight: 600;
    margin-top: 3px;
}
.Advance-Search-university-card-one{
    display: flex;
    justify-content: space-between;
}
.Advance-Search-university-card-two{
    display: flex;
    justify-content: space-between;
}
.Advancesearch-Intake-button,.Advancesearch-Intake-button-open,.Advance-Home-Course-Download,.Advancesearch-Intake-button-close{
    padding: 5px 10px;
    margin-right: 10px;
    background-color: #ccc;
    font-size: 13px;
    color: grey;
    border: none;
    border-radius: 20px;
}
.Advancesearch-Intake-button-open{
    background-color: green;
    color: #fff;
    border-radius: 0px;
}
.Advancesearch-Intake-button-close{
    background-color: red;
    color: #fff;
    border-radius: 0px;
}

.Advance-Home-Course-Download{
    background-color: #fff;
    color: #115277;
}
.Advance-search-ranking{
    border: 1px solid #ccc;
    background-color: #ccc;
    color: #999;
    padding:5px;
    text-align: left;
    margin: 0px;
}
@media screen and (max-width: 1024px) {
    .Advance-Search-university-card-one {
        width: 100%;
    }
    .Advance-Search-filter, .Advance-Search-Coursesfound {
        margin: 0px;
    }
   
}
@media screen and (max-width: 768px) {
.Advance-Search-page{
   flex-direction: column-reverse;
   gap: 0px;
}
.Advance-Search-filter,.Advance-Search-Coursesfound{
    width: 100%;
    margin: 0px;
}
.Advance-Search-Coursesfound{
    width: 100%;
}
}

@media screen and (max-width: 500px) {
    .Advance-Search-page{
       flex-direction: column-reverse;
    }
    .Advance-Search-filter,.Advance-Search-Coursesfound{
        width: 100%;
        margin: 0px;
    }
    .Advance-Search-Coursesfound{
        width: 100%;
    }
    .Advance-Search-university-card-one {
       flex-direction: column;
    }
    .Advance-Search-university-card-two {
        flex-direction: column;
    }
    .Advancesearch-main-heading{
        text-align: center;
    }
   
    .Advance-search-ranking{
       text-align: center;
    }
    }

/*student modal*/

  .Modal-form{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px;
  }
  
  .Modal-form h2{
    color: #115277;
  }
  .modal-close-btn {
    position: absolute;
    right: 30px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #999;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius:20px;
    background-color: #ccc;
  }
  
  .Modal-form-content{
    padding: 20px;
  }
  /* Style for form */
  .modal-form-group {
    margin-bottom: 20px;
  }
  
  .modal-form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .modal-form-group input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .modal-form-group input:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .modal-button[type="submit"] {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .modal-button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
 


  .Compare{
    position: fixed;
    bottom: 32px;
    width: 455px;
    left: 50px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  
  .Comparison-count{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
    border: 2px solid #ccc;
    background-color: #ccc;
    padding: 20px;
  }
  .Compare-button{
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #115277;
    color: #fff;
    border: none;
    font-size: 14px;
  }
  
 /* Modal */
.searchpage-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100% !important;
    max-width: 700px !important;
    max-height: 70%;
    overflow: auto;
 
  }

  .searchpage-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75); /* Black background with opacity */
    z-index: 999; /* Ensure it covers other elements */
  }
  
  
  /* Table */
  .searchpage-modal table {
    width: 100%;
    border-collapse: collapse;
 
  }
  
  .searchpage-modal th,
  .searchpage-modal td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .searchpage-modal th {
    background-color: #f2f2f2;
    white-space: nowrap; 
  }
  .modal-close-button{
    margin: 10px;
    padding: 10px 20px;
    color: #fff;
    background-color: red;
    border: none;
    font-size: 16px;
  }
  .modal-download-button{
    margin: 10px;
    padding: 10px 20px;
    color: #fff;
    background-color:#115277;
    border: none;
    font-size: 16px;
  }
  
  /*pagination*/
.pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination li {
    margin: 0 5px;
    cursor: pointer;
  }
  
  .pagination li a {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
    text-decoration: none;
  }
  
  .pagination li.active a {
    background-color: #115277;
    color: #fff;
    border-color: #115277;
  }
  
/* 
  .search-container {
    width: 100%;
  }
  
  .search-container-main {
    display: flex;
    flex-direction: column;
  }
  
  .search-box {
    margin-bottom: 15px;
  }
  
  .search-box label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .search-inbox2, .state-dropdown {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  .state-dropdown {
    position: relative;
  }
  
  .suggestion-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    border: 1px solid #ccc;
    border-top: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
   */