/*banner*/
.About-Img-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;
  padding-top: 40px;
  padding-bottom: 20px;
  background:url(../../assets/college.png) right bottom/cover no-repeat;
  height: 460px;
}

.About-container-img-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.About-hero-text {
  position: absolute;
  top: 35%;
  left: 15%;
  transform: translate(-40%, -40%);
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.About-hero-text h1 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 800;
  background: #fff;
  background-clip: text;
  color: transparent;
}


.About-hero-text p {
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  color: #fff;
  padding: 0.5rem 0 2rem 0;

}

.About-Home-link {
  color: #fff;
}

.About-Home-link-span {
  color: #0073e6;
  font-weight: 600;
}


@media screen and (max-width: 768px) {
  .About-hero-text p {
    font-size: 1.1rem;
  }

  .About-hero-text h1 {
    font-size: 2rem;
    font-weight: 800;
    background: #fff;
    background-clip: text;
    color: transparent;
  }

  .About-hero-text {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-height: 555px) {
  .About-hero-text {
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 500px) {
  .About-hero-text {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


@media screen and (max-width: 555px) {
  .About-Img-container {
    height: 300px;
  }
  .About-hero-text {
    top: 33%;
  }
}

/*pagination*/
/* Base pagination styles */
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pagination li {
  margin: 0 5px;
  cursor: pointer;
}

.pagination li a {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  text-decoration: none;
}

.pagination li.active a {
  background-color: #115277;
  color: #fff;
  border-color: #115277;
}

/* Responsive styles */
@media (max-width: 480px) {
  .pagination li a {
    padding: 5px 8px;
  }

  .pagination li {
    margin: 3px 3px;
  }
}
@media (max-width: 350px) {
  .pagination li a {
    padding: 5px;
    margin: 2px;
  }

  .pagination li {
    margin: 3px 0px;
  }
}



/*college*/
.navbar-container{
  height: 100px;
  background-color: #115688;
}
/* Colleges */
.college-one-container {
  text-align: left;
  padding: 60px;
}

.college-one-container h2 {
  text-align: left;
  font-family: "Poppins", sans-serif;
}

.college-one-container p {
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 30px;
}

.college-two-container {
  width: 100%;
  display: flex;
  gap: 20px;
  padding: 20px 60px;
}

.college-two-list {
  width: 70%;
}

.college-two-filter {
  width: 30%;
}

 @media screen and (max-width: 768px) {
  .college-two-container {
    padding: 10px 3%;
  }

  .college-one-container {
    text-align: center;
    padding: 20px;
  }

  .college-one-container h2 {
    text-align: center;
  }

  .college-one-container p {
    text-align: center;
  }


.college-two-container {
  flex-direction: column-reverse;
  padding: 10px 3%;
}

.college-two-list {
  width: 100%;
}

.college-two-filter {
  width: 100%;
}
} 


.University-card-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.University-card {
  width: calc(50% - 10px);
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  height: 730px;
}

.university-img {
  max-width: 100%;
  height: 320px;
  border-radius: 5px;
}


.University-card-details {
  padding: 10px 0px;
}


.University-address-rating {
  display: flex;
  margin-top: 10px;
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
  gap: 30px;
}

.University-address,
.University-rating {
  display: flex;
  align-items: center;
  color: #707070;
  font-family: "Poppins", sans-serif;
  text-overflow: ellipsis;
}

.University-rating {
  border: 1px solid #fef7f5;
  border-radius: 5px;
  background-color: #fef7f5;
  padding: 3px 5px;
}

.University-name {
  text-align: left;
  color: #115688;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.University-address-icon,
.University-address-icon-star {
  margin-right: 10px;
}

.University-address-icon-star {
  color: #115688;
  margin-right: 5px;
}

.University-box {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  height: 180px;
  margin-bottom: 20px;
}

.University-box-inside,
.University-box-inside2 {
  width: 30%;
  padding: 0 5px;
  font-family: "Poppins", sans-serif;
}

.University-box-inside-one{
  height: 90px;
}

.University-box-inside2 {
  width: 38%;
  border-left: 2px solid #ccc;
  border-right: 2px solid #ccc;
  font-family: "Poppins", sans-serif;
}

.University-heading,
.University-heading2 {
  color: #a5a6a2;
  font-weight: 400;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  text-align: left;
  width: 150px;
}

.University-heading2 {
  padding-top: 15px;
  font-family: "Poppins", sans-serif;
}

.University-heading-values {
  color: #666666;
  font-size: 15px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  padding-top: 5px;
}

.University-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.University-brocher-button {
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  font-weight: 500;
  border-radius: 20px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 40px;

}

.University-rating-button {
  padding: 8px 30px;
  background-color: #115688;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  border-radius: 20px;
  border: none;
  font-size: 16px;
  white-space: nowrap;
}

@media screen and (max-width: 1300px) {
  .University-card-row {
    flex-direction: column;
  }

  .University-card {
    width: calc(65% - 10px);

  }
}

@media screen and (max-width: 1100px) {
  .University-card {
    width: calc(80% - 10px);

  }
}




/* @media screen and (max-width: 1300px) {
  .University-card {
      width: calc(65% - 10px);
  }
} */

@media screen and (max-width: 768px) {
  .University-card {
   width: calc(70% - 10px);
  }
  .university-img {
    max-width: 100%;
    height: 300px;
    border-radius: 5px;
  }
}
@media screen and (max-width: 700px) {
  .University-card {
   width: calc(100% - 10px);
   height: fit-content;
  }
  .University-buttons{
    gap: 10px !important;
    flex-direction: column;
   }
   .University-box {
   flex-direction: column;
   }
   .University-box-inside, .University-box-inside2 {
    width: 100%;
   }
   .University-box-inside2 {
    width: 100%;
    border-left: none; 
    border-right: none;
    font-family: "Poppins", sans-serif;
}
.University-box,.University-box-inside-one  {
  height: fit-content;
}
}
/*filter*/
.filter-sidebar {
  padding: 10px;
  max-width: 500px;
  margin: auto;
}

.filter-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: 10px;
  border-bottom: 1px solid #ccc;
}

.allfilter {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.allfilter-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  font-weight: 500;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.clear {
  color: #0067DA;
  font-weight: 500;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.filter-section-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ccc;
  text-align: left;
}

.filter-label-name {
  text-align: left;
  font-weight: 400;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 8px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

.filter-label-container {
  max-height: 200px;
  overflow-y: auto;
  margin: 10px;
}

.filter-label-container::-webkit-scrollbar {
  width: 5px;
}

.filter-label-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.label-section-heading {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}


/*rate my chance*/
.rate-mychance-main-Container {
  max-width: 800px;
  margin: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
}

.rate-my-chance-Container1 {
  padding: 20px 10%;
}

.rate-my-chance-Container2 {
  padding: 20px 0%;
}

.ratemy-chance-heading {
  padding: 10px;
  color: #115688;
  font-family: "Poppins", sans-serif;
}

.ratemy-chance-form-check {
  display: flex;
  justify-content: center;
  gap: 10px;
  text-align: justify;
  margin: 10px;
  align-items: center;
}

.rate-my-chance-section1 h3 {
  text-align: left;
  margin: 5px;
  font-family: "Poppins", sans-serif;
}

.rate-my-chance-section1 p {
  text-align: left;
  margin: 5px;
  font-family: "Poppins", sans-serif;
}

.select-input-field {
  padding: 10px 10px 10px 10px;
  width: 70%;
}

.select-input-field1 {
  padding: 10px;
  width: 67%;
  color: #ccc;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}

.ratemy-chance-button {
  padding: 10px 40px;
  margin: 10px;
  color: #fff;
  background-color: #115688;
  border: none;
  font-size: 16px;
}

.rate-mychance-main-Container {
  text-align: center;
}

.form-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select-input-field {
  width: 70%;
  min-width: 200px;
  text-align: left;
}

.error {
  color: red;
  margin-top: 5px;
}

.ratemy-chance-form-check {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.ratemy-chance-form-check input {
  margin-right: 10px;
}

.ratemy-chance-button {
  margin-top: 20px;
  padding: 10px 20px;
  cursor: pointer;
}

/*modal*/
.modal-rate-mychance {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.modal-overlay-rate-mychance {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal-rate-mychance h2 {
  margin-top: 0;
}

.modal-rate-mychance button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-rate-mychance button:hover {
  background-color: #0056b3;
}

.ReactModal__Content {
  position: sticky !important;
  max-width: 400px !important;
}

.ReactModal__Overlay {
  position: absolute;
  z-index: 10;
  top: 11% !important;
  left: 0;
  right: 0;
  bottom: -35% !important;
  margin: 0 !important;
}


/* Collegedetails.css */
.college-details {
  padding: 0px;
}

.college-details-image {
  max-width: 100%;
  height: 300px;
  border-radius: 10px;
}

.college-details-content-main {
  margin: 20px 10%;
}

.college-details-content {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #ccc;
}

.college-details-address {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  padding: 5px;
  gap: 10px;
}

.college-details-content1 {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

.college-details-content2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.college-details-brochure-button,
.college-details-rate-button {
  padding: 10px 30px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

.college-details-rate-button {
  background-color: #115688;
  color: #fff;
}


.college-details-tabs {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}

.college-details-tab-button {
  background-color: #e0e0e0;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

.college-details-tab-button.active {
  background-color: #115688;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: white;
}

.college-details-tab-content {
  margin-top: 20px;
}

.college-details-tab-pane {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

/*scholarships*/
.scholarships {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.scholarship-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  margin-bottom: 10px;
}

.scholarship-table th,
.scholarship-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  border: none;
}

.scholarship-table td {
  width: 20%;
}

.scholarship-table th {
  background-color: #f2f2f2;
  color: #333;
}


.scholarships-heading {
  text-align: left;
  color: #115688;
}

.scholarships-container {
  padding-top: 30px;
}

.Collegeinfo {
  text-align: left;
}

.Collegeinfo h2 {
  text-align: left;
  padding: 10px;
}

.Collegeinfo p {
  text-align: left;
  padding: 10px;
}
/*fee page*/
 .fee-page {
  margin: 0 auto;
}

.fee-page h1 {
  text-align: left;
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 400;
}
.fee-page h2 {
  text-align: left;
  color: #4a4a4a;
}
.fee-section-heading{
  display: flex;
  align-items: center;
  gap:20px
}
.fee-section-rupeeIcon{
  width: 60px;
  height: 60px;
  border: 1px solid #ddd;
  padding: 10px;
  color: #0056b3;
  border-radius: 5px;
  background-color: #ddd;
}
.fee-page-section {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 10px 0;
  padding: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fee-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}


.fee-section-header h3, .fee-section-header h4 {
  margin: 0;
  text-align: left;
}
.fee-section-title{
  font-size: 18px;
  font-weight: 700;
  color: #333;
}
.fee-page-section-paragraph{
  text-align: justify;
  font-size: 16px;
  line-height: 28px;
}

.fee-page-section-icon {
  font-size: 24px;
}

.fee-page-section-content {
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  text-align: left;
}

.fee-page-section-table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.fee-page-section-table th, .fee-page-section-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.fee-page-section-table th {
  background-color: #F3F6FF;
  font-weight: bold;
}

.fee-page-section-table tr:nth-child(even) {
  background-color: #f9f9f9;
}


.fee-page-section-table td {
  white-space: pre-line; /* This ensures new lines are respected */
}

.table-of-contents {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.table-of-contents li {
  margin: 5px 0;
}

.table-of-contents a {
  color: #0066cc;
  text-decoration: none;
}

.table-of-contents a:hover {
  text-decoration: underline;
}